import head from 'lodash/head';

import {handleError, hideLoader, showLoader} from 'store/actions';

import * as ownerRequests from 'core/gateways/OwnerApiGateway/requests';

import {LIST_TABS} from 'pages/Owners/constants/tabs';
import * as types from 'pages/Owners/redux/actionTypes';
import {OwnersListDeprecatedResponse, OwnersListResponse} from 'pages/Owners/types/api';

import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import {transformDataToRequestBody} from '../mappers/list';

let controller = new AbortController();

const receiveOwners = (response: OwnersListDeprecatedResponse | OwnersListResponse): types.ReceiveOwners => {
    if ('pagination' in response.data && 'items' in response.data) {
        return {
            type: types.OWNERS_RECEIVED,
            payload: {
                data: response.data.items,
                pagination: response.data.pagination,
            },
        };
    }
    return {
        type: types.OWNERS_RECEIVED,
        payload: {
            data: response.data,
            pagination: parsePaginationHeaders(response.headers),
        },
    };
};

export const getOwners = () => async (dispatch, getState) => {
    const state = getState();

    const requestPayload = transformDataToRequestBody({state});

    try {
        dispatch(showLoader());

        const response = await ownerRequests.searchOwners({requestPayload});

        dispatch(receiveOwners(response));
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch(hideLoader());
    }
};

export const setSearchParams = (payload): types.SetSearchParams => ({
    type: types.OWNERS_SET_SEARCH_PARAMS,
    payload,
});

export const setCurrentPage = (page): types.SetCurrentPage => ({
    type: types.OWNERS_SET_CURRENT_PAGE,
    payload: page,
});

export const setPagination = (currentPage, perPage): types.SetPagination => ({
    type: types.OWNERS_SET_PAGINATION,
    payload: {currentPage, perPage},
});

export const setListTab = (params: {tab: keyof typeof LIST_TABS}) => (dispatch) => {
    const {tab} = params;

    dispatch(setCurrentPage(1));
    dispatch({type: types.SET_ACTIVE_LIST_TAB, payload: tab});
};

export const setArchivedOwners = () => (dispatch) => {
    dispatch(setCurrentPage(1));
    dispatch(setListTab({tab: LIST_TABS.ARCHIVED}));
    dispatch(getOwners());
};

export const clearState = () => ({
    type: types.CLEAR_STATE,
});

export const getOwnerPhoneNumber = async (params: {ownerID: string}): Promise<string | undefined> => {
    const {ownerID} = params;

    controller.abort();
    controller = new AbortController();

    try {
        const {data} = await ownerRequests.revealOwnerHiddenData({ownerID}, {signal: controller.signal});

        return head(data)?.mobile_phone;
    } catch (error) {
        console.log(error);
    }
};

export const getOwnerCoordinatorPhoneNumber = async (params: {ownerID: string}): Promise<string | undefined> => {
    const {ownerID} = params;

    controller.abort();
    controller = new AbortController();

    try {
        const {data} = await ownerRequests.revealOwnerHiddenData({ownerID}, {signal: controller.signal});

        return head(data)?.coordinator_mobile_phone;
    } catch (error) {
        console.log(error);
    }
};

export const getOwnerEmail = async (params: {ownerID: string}): Promise<string | undefined> => {
    const {ownerID} = params;

    controller.abort();
    controller = new AbortController();

    try {
        const {data} = await ownerRequests.revealOwnerHiddenData({ownerID}, {signal: controller.signal});

        return head(data)?.email;
    } catch (error) {
        console.log(error);
    }
};
