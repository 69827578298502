import React from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, InjectedFormProps, reduxForm} from 'redux-form';

import {AppState} from 'store';

import Button from 'components/ui/Buttons/Button';
import RadioInput from 'components/ui/Form/RadioInput/index';
import SingleOwnersAsyncSelect from 'components/ui/Form/SingleOwnersAsyncSelect';

import validate from './validation';

const FORM_NAME = 'choose-owner-form';

interface OwnProps {
    onClose();
}

interface StateProps {
    isFormDisabled?: boolean;
    driverIsOwner: boolean;
}

type AllFormProps = OwnProps & StateProps;

const Form = (props: InjectedFormProps<{}, AllFormProps> & AllFormProps): JSX.Element => {
    const {onClose, isFormDisabled, pristine, submitting, driverIsOwner, handleSubmit} = props;

    return (
        <form onSubmit={handleSubmit} className="tm-confirm tm-check-in">
            <div className="modal-body__main">
                <div className="modal-body__main_item ">
                    <Field
                        name="driverIsOwner"
                        component={RadioInput}
                        disabled={isFormDisabled}
                        items={[
                            {label: 'Current Driver is working with owner', value: false},
                            {label: 'Current driver has owner profile', value: true},
                        ]}
                    />
                    <div className="row mt15">
                        <div className="col-md-6">
                            <SingleOwnersAsyncSelect
                                // `key` here is used to trigger fetch when `driverIsOwner` changes
                                // If key is the same, select will use cache insead of new request
                                key={`${driverIsOwner}`}
                                name="owner"
                                valueAsObject
                                label="Owner"
                                isDisabled={isFormDisabled}
                                placeholder="choose owner from the list"
                                withoutOwnerDriver={driverIsOwner}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-body__bottom">
                <div>
                    <Button disabled={isFormDisabled} onClick={onClose}>
                        Cancel
                    </Button>
                </div>
                <div>
                    <Button type="submit" colorTheme="green" disabled={pristine || submitting || isFormDisabled}>
                        Save
                    </Button>
                </div>
            </div>
        </form>
    );
};

const ReduxForm = reduxForm<{}, AllFormProps>({form: FORM_NAME, enableReinitialize: true, validate});

export default connect(
    (state: AppState): StateProps => ({
        driverIsOwner: formValueSelector(FORM_NAME)(state, 'driverIsOwner'),
    }),
)(ReduxForm(Form));
