import {checkIsTruckStatusNotAvailable} from 'core/entities/Truck/modules/truckStatus';
import Truck from 'core/entities/Truck/types';

import {getPreferredLoadsOptions} from 'components/common/Truck/TruckPreferredLoads/utils';

import {getCurrentDate} from 'utils/dateTime';
import {getCityLineFromObject} from 'utils/getCityLine';

import {statusOptions} from '../components/Form/constants';

export const getTruckLocation = (truck: Truck) => {
    const {lastKnownData} = truck;
    const cityLine = lastKnownData?.location?.cityLine ? getCityLineFromObject(lastKnownData?.location?.cityLine) : '';

    return {
        longitude: lastKnownData?.location?.coordinates?.longitude,
        latitude: lastKnownData?.location?.coordinates?.latitude,
        country: lastKnownData?.location?.cityLine?.country,
        state: lastKnownData?.location?.cityLine?.state,
        city: lastKnownData?.location?.cityLine?.city,
        zip: lastKnownData?.location?.cityLine?.zip,
        cityLine,
    };
};

export const createInitFormValues = (params: {truck: Truck}) => {
    const {truck} = params;

    const {status, preferred_loads} = truck;

    const {originalDate: date, originalTime: time, timeZone} = getCurrentDate();

    const isTruckStatusNotAvailable = checkIsTruckStatusNotAvailable(truck);

    const statusOption = statusOptions.find(({value}) => value === status);
    const preferredLoadOption = getPreferredLoadsOptions().find(({value}) => value === preferred_loads);

    return {
        location: isTruckStatusNotAvailable ? getTruckLocation(truck) : null,
        preferredLoads: preferredLoadOption,
        truckDateTime: {
            timeZone,
            date,
            time,
        },
        status: statusOption,
    };
};
