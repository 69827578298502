import React, {useMemo} from 'react';
import {Field} from 'redux-form';

import Carrier from 'core/entities/Carrier/types';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import useSelectCompaniesState from 'pages/Owners/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import useSyncTableRows from 'pages/Owners/components/modals/SelectCompanies/hooks/useSyncTableRows';
import useOwnerFormsNames from 'pages/Owners/hooks/useOwnerFormsNames';

import {highlightSubstring} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    company: Carrier;
    index: number;
};

const CompanyNameInfo: React.FC<OwnProps> = (props) => {
    const {company, index} = props;

    const {isCompanyLoadPlannedOrInProgress} = useSyncTableRows(company);
    const {selectCompaniesModal: getName} = useOwnerFormsNames();
    const {searchParams} = useSelectCompaniesState();

    const tooltipContent = (
        <>
            You cannot turn off the company because <br /> the load is planned or in progress
        </>
    );

    const checkBoxLabel = highlightSubstring(company.company_name, searchParams?.companyName);

    const memoizedField = useMemo(
        () => (
            <Field
                valueAs={{label: company.company_name, value: company.id}}
                name={`${getName('selectedCompanies')}.${index}`}
                disabled={isCompanyLoadPlannedOrInProgress}
                component={CheckBoxInput}
                position="right"
            />
        ),
        [],
    );

    return (
        <div className={styles.wrap}>
            {memoizedField}
            {checkBoxLabel}

            {isCompanyLoadPlannedOrInProgress ? (
                <div className={styles.badge}>
                    <TooltipBootstrap tooltipContent={tooltipContent}>
                        <i className="fa fa-exclamation-triangle" />
                    </TooltipBootstrap>
                </div>
            ) : null}
        </div>
    );
};

export default CompanyNameInfo;
