import {useSelector} from 'react-redux';

import * as selectors from 'pages/Drivers/selectors';

type UseDriverModalsState = {
    isRestoreAccount: ReturnType<typeof selectors.getDriverIsRestoreAccount>;
};

const useDriverModalsState = (): UseDriverModalsState => ({
    isRestoreAccount: useSelector(selectors.getDriverIsRestoreAccount),
});

export default useDriverModalsState;
