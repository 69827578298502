import React from 'react';

type OwnProps = {
    message?: string;
};

const Success: React.FC<OwnProps> = (props) => {
    const {message} = props;

    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-check feedback-icon-success" />
            </div>
            <div className="col-11">
                <p>{message}</p>
            </div>
        </div>
    );
};

export default Success;
