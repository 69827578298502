import {combineReducers} from 'redux';

import current from './current';
import list from './list';
import modals from './modals';

export default combineReducers({
    list,
    modals,
    current,
});
