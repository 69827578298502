export const approvementItems = [
    {label: '- None -', value: ''},
    {label: 'Approved', value: true},
    {label: 'Not approved', value: false},
];

export const COMPANY_NAME_FIELD_MAX_LENGTH = 100;

export const paymentTerms = {
    dueOnReceipt: {label: 'Due on receipt', value: 'due_on_receipt', apiValue: 'due_on_receipt'},
    net7days: {label: 'Net 7 Days', value: 'net_days_7', apiValue: 'net_days'},
    net15days: {label: 'Net 15 Days', value: 'net_days_15', apiValue: 'net_days'},
    net30days: {label: 'Net 30 Days', value: 'net_days_30', apiValue: 'net_days'},
    net45days: {label: 'Net 45 Days', value: 'net_days_45', apiValue: 'net_days'},
    net60days: {label: 'Net 60 Days', value: 'net_days_60', apiValue: 'net_days'},
    weekly: {label: 'Weekly', value: 'weekly', apiValue: 'weekly'},
    monthly: {label: 'Monthly', value: 'monthly', apiValue: 'monthly'},
    custom: {label: 'Custom', value: 'custom', apiValue: 'custom'},
} as const;

export const weekDays = {
    sunday: {label: 'Sunday', value: '7'},
    monday: {label: 'Monday', value: '1'},
    tuesday: {label: 'Tuesday', value: '2'},
    wednesday: {label: 'Wednesday', value: '3'},
    thursday: {label: 'Thursday', value: '4'},
    friday: {label: 'Friday', value: '5'},
    saturday: {label: 'Saturday', value: '6'},
} as const;
