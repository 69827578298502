import {isDriverOfferExpired} from 'core/entities/Quote/modules/offer/offerProgress';
import {
    isAllowedSendQuoteToDriver,
    isDriverDeclinedQuote,
    isDriverProvidedQuote,
    isDriverReadQuote,
    isNotAllowedSendQuoteToDriver,
} from 'core/entities/Quote/modules/quoteDriverReceiver';
import {DriverOffer, DriverReceiverWithTruck} from 'core/entities/Quote/types';
import Truck from 'core/entities/Truck/types';

import {driverFilterOptions} from 'pages/LoadBoard/constants/quoteReceiversModal';

import {updateItem} from 'utils/normalizer';

import {FilterOptions} from '../quoteReceivers';
import {ListState} from '../types';

export const findDriverOfferByTruckID = (state: ListState, truckID: string) => {
    const offer = Object.values(state.offers.byId).find((offerData) => {
        return offerData?.truckID === truckID;
    });
    return offer;
};

export const findDriverReceiverByTruckID = (state: ListState, truckID: string) => {
    const receiver = Object.values(state.quoteReceivers.byId).find((receiverData) => {
        return receiverData?.truck?.id === truckID;
    });
    return receiver;
};

export const updateTruckReserveInDriverOffers = (params: {
    state: ListState;
    quoteDriverOffer: DriverOffer;
    truckReserve: Truck['reserve'];
}) => {
    const {state, quoteDriverOffer, truckReserve} = params;
    const offerWithReservedTruck = {...quoteDriverOffer, truck: {...quoteDriverOffer.truck, reserve: truckReserve}};
    const updatedOffers = updateItem<DriverOffer>(state.offers, offerWithReservedTruck);
    return updatedOffers;
};

export const updateTruckReserveInDriversReceivers = (params: {
    state: ListState;
    quoteDriverReceiver: DriverReceiverWithTruck;
    truckReserve: Truck['reserve'];
}) => {
    const {state, quoteDriverReceiver, truckReserve} = params;
    const receiverWithReservedTruck = {
        ...quoteDriverReceiver,
        truck: {...(quoteDriverReceiver.truck as Truck), reserve: truckReserve},
    };
    const updatedReceivers = updateItem<DriverReceiverWithTruck>(state.quoteReceivers, receiverWithReservedTruck);
    return updatedReceivers;
};

export const calculateExpiredOffers = (
    currentOffers: ListState['offers'],
    currentExpiredOffers: ListState['expiredOffers'],
): ListState['expiredOffers'] => {
    if (currentOffers.allIds.length === 0) {
        return currentExpiredOffers;
    }

    const expiredOffersMap = currentOffers.allIds
        .map((offerID) => currentOffers.byId[offerID])
        .reduce((result, offer) => {
            if (!result[offer.id]) {
                result[offer.id] = isDriverOfferExpired(offer);
            }
            return result;
        }, {} as ListState['expiredOffers']);

    return expiredOffersMap;
};

export const isWebsocketAction = (action: {type: string}): boolean => {
    return action.type.endsWith('_WS') || action.type.startsWith('WEB_SOCKET_');
};

export const getFilteredItems = (params: {
    items: DriverReceiverWithTruck[];
    options: FilterOptions[];
}): DriverReceiverWithTruck[] => {
    const {items, options} = params;

    return items.filter((item) =>
        options.some((option) => {
            if (option === driverFilterOptions.allowedSendToDriver) {
                return isAllowedSendQuoteToDriver(item);
            }

            if (option === driverFilterOptions.notAllowedSendToDriver) {
                return isNotAllowedSendQuoteToDriver(item);
            }

            if (option === driverFilterOptions.driverReadQuote) {
                return isDriverReadQuote(item);
            }

            if (option === driverFilterOptions.driverDeclinedQuote) {
                return isDriverDeclinedQuote(item);
            }

            if (option === driverFilterOptions.driverProvidedQuote) {
                return isDriverProvidedQuote(item);
            }

            return false;
        }),
    );
};

export const getCurrentOptions = (params: {
    options: FilterOptions[];
    currentOption: FilterOptions;
}): FilterOptions[] => {
    const {options, currentOption} = params;

    const isChecked = options.find((status) => status === currentOption);
    let currentOptions: FilterOptions[] = [];

    if (isChecked) {
        currentOptions = options.filter((status) => status !== currentOption);
    } else {
        currentOptions = [...options, currentOption];
    }

    return currentOptions;
};
