import {push} from 'connected-react-router';

import {getTruckLinks} from 'routing/linkBuilder';

import {AppThunk} from 'store';
import {handleError, hideLoader, showLoader} from 'store/actions';

import requests, {updateDispatcherNote} from 'core/gateways/TruckApiGateway/requests';
import Truck from 'core/entities/Truck/types';

import * as types from '../actionTypes';

export function goToTruckView(truck: Truck): AppThunk {
    return function (dispatch) {
        const truckLinks = getTruckLinks(truck);
        dispatch(push(truckLinks.view));
    };
}

export function goToTruckList(): AppThunk {
    return function (dispatch) {
        const truckLinks = getTruckLinks();
        dispatch(push(truckLinks.list));
    };
}

export function receiveDispatcherNote(payload): types.CurrentTruckActionTypes {
    return {
        type: types.TRUCK_DISPATCHER_NOTE_UPDATED,
        payload,
    };
}

export function updateTruckDispatcherNote(truck: Truck, text: string): AppThunk {
    return function (dispatch) {
        dispatch(showLoader());
        updateDispatcherNote(truck.id, {dispatcher_note: text})
            .then(({data}) => dispatch(receiveDispatcherNote({truck, note: data})))
            .catch((error) => dispatch(handleError(error)))
            .finally(() => dispatch(hideLoader()));
    };
}

function receiveGeneralNote(payload): types.CurrentTruckActionTypes {
    return {
        type: types.TRUCK_NOTE_ADDED,
        payload,
    };
}

export function postGeneralNote(note: string, truckID: string): AppThunk {
    return function (dispatch) {
        dispatch(showLoader());
        requests
            .postTruckNote(truckID, note)
            .then((postedNote) => dispatch(receiveGeneralNote(postedNote)))
            .catch((error) => dispatch(handleError(error)))
            .finally(() => dispatch(hideLoader()));
    };
}
