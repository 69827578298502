import React from 'react';
import {Modal} from 'react-bootstrap';
import classNames from 'classnames';

import {getDriverName} from 'core/entities/Driver/modules/driverName';
import Driver from 'core/entities/Driver/types';

import Button from 'components/ui/Buttons/Button';

import useDriverActions from 'pages/Drivers/hooks/useDriverActions';
import useDriverModalsState from 'pages/Drivers/hooks/useDriverModalsState';

import styles from './style.module.scss';

type OwnProps = {
    data: {driver: Driver};
    handlers: {onConfirm: (isWithAccount: boolean) => void};
    onClose: () => void;
};

const RestoreDriverProfileModal = (props: OwnProps): JSX.Element => {
    const {
        data: {driver},
        handlers: {onConfirm},
        onClose,
    } = props;

    const {setIsRestoreDriverAccount} = useDriverActions();
    const {isRestoreAccount} = useDriverModalsState();

    const driverName = getDriverName(driver);
    const withAccountText = isRestoreAccount ? ' and mobile account' : '';

    return (
        <Modal show={true} onHide={onClose} className={classNames('info-modal', styles.modal)} backdrop="static">
            <Modal.Header>
                <Modal.Title>Restore Profile</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-1">
                        <i className="fa fa-exclamation feedback-icon-reset" />
                    </div>
                    <div className="col-11">
                        <div>
                            <strong>DO YOU WANT TO RESTORE THE DRIVER?</strong>
                            <div>
                                You are going to restore <strong>{driverName}</strong> TMS driver`s profile
                                {withAccountText}, which will activate all possible actions with them.
                            </div>
                        </div>

                        <div className={styles.login}>
                            <div>
                                <strong>Driver mobile login account: </strong>
                            </div>
                            <div className="ml10">{driver.email}</div>
                        </div>

                        <div className="mt25">
                            <span>WHAT DO YOU WANT TO RESTORE?</span>
                            <div className={styles.choices}>
                                <button type="button" onClick={() => setIsRestoreDriverAccount(true)}>
                                    <span className={classNames(styles.radio, {[styles.checked]: isRestoreAccount})} />
                                    <label htmlFor="withRestoreAccount">Driver TMS profile and mobile account</label>
                                </button>
                                <button type="button" onClick={() => setIsRestoreDriverAccount(false)}>
                                    <span className={classNames(styles.radio, {[styles.checked]: !isRestoreAccount})} />
                                    <label htmlFor="withoutRestoreAccount">Driver TMS profile only</label>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onClose} className="mr10" colorTheme="grey" buttonSize="small" defaultFocus={true}>
                    KEEP
                </Button>
                <Button onClick={onConfirm} className="mr10" colorTheme="yellow" buttonSize="small" defaultFocus={true}>
                    RESTORE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RestoreDriverProfileModal;
