import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import {LIST_TABS} from 'pages/Owners/constants/tabs';
import * as selectors from 'pages/Owners/redux/selectors';

export const transformDataToRequestBody = (params: {state}) => {
    const {state} = params;

    const currentSortFrom = selectors.getOwnersSortFrom(state);
    const searchParams = selectors.getListSearchParams(state);
    const currentSortBy = selectors.getOwnersSortBy(state);
    const pagination = selectors.getListPagination(state);
    const tab = selectors.getActiveListTab(state);

    const requestPayload = {
        ...searchParams,

        sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : null,
        is_deactivated: tab === LIST_TABS.DEACTIVATED,
        'per-page': Number(pagination.perPage),
        is_deleted: tab === LIST_TABS.ARCHIVED,
        page: Number(pagination.currentPage),
    };

    return omitBy(requestPayload, (value) => isNull(value) || isUndefined(value));
};
