import type {SearchTrucksOnMapDTO, SearchTrucksOnMapResultDTO, TruckApiGateway} from '../types';

type Deps = {
    truckApiGateway: TruckApiGateway;
};

const searchTrucksOnMap = async function (data: SearchTrucksOnMapDTO, deps: Deps): Promise<SearchTrucksOnMapResultDTO> {
    const {truckApiGateway} = deps;
    const trucksOnMap = await truckApiGateway.searchListTrucksOnMap(data);
    return {
        trucks: trucksOnMap,
    };
};

export default searchTrucksOnMap;
