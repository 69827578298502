import toLower from 'lodash/toLower';

import * as appActions from 'store/actions';

import Load from 'core/entities/Load/types';
import {transformAddEventDataToRequestBody} from 'core/entities/LocationEvents/mappers/addEventCheckCall';
import {AddBrokerUpdateResponse, BrokerUpdateRequestBody, LocationEvent} from 'core/entities/LocationEvents/types';
import {getTravelOrderNumber} from 'core/entities/TravelOrder/modules/common/getTravelOrderNumber';
import TravelOrder from 'core/entities/TravelOrder/types';
import {AutoCheckCallFilterStatus} from 'core/entities/TravelOrder/types/AutoCheckCallSettings';
import {EventTimer} from 'core/entities/TravelOrder/types/EventTimer';
import {TripMonitorSSEData} from 'core/entities/TravelOrder/types/SSE';
import locationEventApiGateway from 'core/gateways/LocationEventApiGateway';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';
import {addLoadNotes} from 'core/useCases/Load';

import {AddCheckCallFormValues} from 'components/common/LocationEvents/modals/CheckCallModal/types';
import {closeAll} from 'components/ui/ModalProvider/actions';

import {SORT_BY_VALUES, SORT_ORDER} from 'pages/TravelOrders/constants/list';
import * as types from 'pages/TravelOrders/redux/actionTypes/list';
import {transformEventTimerDataToRequestBody} from 'pages/TravelOrders/redux/mappers/eventTimer';
import {transformSearchDataToRequestBody} from 'pages/TravelOrders/redux/mappers/tripMonitor';
import * as selectors from 'pages/TravelOrders/redux/selectors';
import {TimerSettingsFormValues, TripMonitorSearchFormValues} from 'pages/TravelOrders/types/formTypes';

import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import Pagination from 'types/Pagination';

import {showSendBrokerUpdateModal} from '../modals';

export const tripMonitorActionCreators = {
    setTripMonitorMetaInformation: (payload: {meta: Partial<{late: number; planned: number; withMobileApp: number}>}) =>
        ({type: types.SET_TRIP_MONITOR_META_INFORMATION, payload} as const),
    setTripMonitorSortBy: (payload: {sortBy: string}) => ({type: types.SET_TRIP_MONITOR_SORT_BY, payload} as const),
    setTripMonitorTab: (payload: {tab: string}) => ({type: types.SET_TRIP_MONITOR_TAB, payload} as const),
    setTripMonitorSearchParams: (payload: {searchParams: Partial<TripMonitorSearchFormValues>}) =>
        ({type: types.SET_TRIP_MONITOR_SEARCH_PARAMS, payload} as const),
    eventReceived: (payload: {event?: LocationEvent; travelOrderNumber: number; etaInfo: TravelOrder['etaInfo']}) =>
        ({type: types.EVENT_RECEIVED, payload} as const),
    addBrokerUpdate: (payload: {data?: AddBrokerUpdateResponse; travelOrderNumber: number}) =>
        ({type: types.ADD_BROKER_UPDATE_TO_EVENT, payload} as const),
    setSSEData: (payload: {data: TripMonitorSSEData[]}) => ({type: types.SSE_DATA_RECEIVED, payload} as const),
    eventTimerReceived: (payload: {eventTimer: EventTimer; travelOrderNumber: number}) =>
        ({type: types.EVENT_TIMER_RECEIVED, payload} as const),
    clearTripMonitorState: () => ({type: types.CLEAR_TRIP_MONITOR_STATE} as const),
    receivedGeneralNoteTravelOrder: (payload: {travelOrder: TravelOrder}) =>
        ({type: types.RECEIVED_GENERAL_NOTE_TRAVEL_ORDER, payload} as const),
    receivedLoadNoteTravelOrder: (payload: {load?: Load}) =>
        ({type: types.RECEIVED_LOAD_NOTE_TRAVEL_ORDER, payload} as const),
    setTripMonitorAutoEventStatus: (payload: {status: AutoCheckCallFilterStatus | null}) =>
        ({type: types.TRIP_MONITOR_AUTO_EVENT_STATUS_RECEIVED, payload} as const),
    setTripMonitorPagination: (payload: {pagination: Pagination}) =>
        ({type: types.SET_TRIP_MONITOR_PAGINATION, payload} as const),
    setTripMonitorSortOrder: (payload: {sortOrder: string}) =>
        ({type: types.SET_TRIP_MONITOR_SORT_ORDER, payload} as const),
    travelOrdersReceived: (payload: {travelOrders: TravelOrder[]}) =>
        ({type: types.TRAVEL_ORDERS_RECEIVED, payload} as const),
    clearSearchParams: () => ({type: types.CLEAR_SEARCH_PARAMS} as const),
};

const getTravelOrders = (params: {requestBody}) => async (dispatch) => {
    const {requestBody} = params;

    try {
        dispatch(appActions.showLoader());

        const {data, headers} = await toRequests.fetchTravelOrderList({requestBody});

        const parsedPagination = parsePaginationHeaders(headers);

        return {items: data?.travelOrders || [], pagination: parsedPagination, meta: data?.meta};
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const getInitTravelOrders = () => async (dispatch, getState) => {
    const state = getState();

    const searchParams = selectors.getTripMonitorSearchParams(state);
    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);
    const tab = selectors.getTripMonitorTab(state);

    const transformedSearchData = transformSearchDataToRequestBody({filters: searchParams});

    const requestBody = {
        ...transformedSearchData,
        page: pagination.currentPage,
        perPage: pagination.perPage,
        orderBy: sorting.sortOrder,
        sortBy: sorting.sortBy,
        byEntity: toLower(tab),
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
};

export const getTravelOrdersByPage = (params: {page: number}) => async (dispatch, getState) => {
    const {page: followingPage} = params;

    const state = getState();

    const searchParams = selectors.getTripMonitorSearchParams(state);
    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);
    const tab = selectors.getTripMonitorTab(state);

    const transformedSearchData = transformSearchDataToRequestBody({filters: searchParams});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        orderBy: sorting.sortOrder,
        sortBy: sorting.sortBy,
        byEntity: toLower(tab),
        page: followingPage,
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
};

export const getTravelOrdersBySortBy = (params: {sortBy: string}) => async (dispatch, getState) => {
    const {sortBy: followingSortBy} = params;

    const state = getState();

    const searchParams = selectors.getTripMonitorSearchParams(state);
    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);
    const tab = selectors.getTripMonitorTab(state);

    const sortOrderByTimerEnding = sorting.sortBy === SORT_BY_VALUES.timerEnding ? SORT_ORDER.DESC : sorting.sortOrder;
    const newSortOrder = followingSortBy === SORT_BY_VALUES.timerEnding ? SORT_ORDER.ASC : sortOrderByTimerEnding;

    const transformedSearchData = transformSearchDataToRequestBody({filters: searchParams});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        sortBy: followingSortBy,
        byEntity: toLower(tab),
        orderBy: newSortOrder,
        page: 1,
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
    dispatch(tripMonitorActionCreators.setTripMonitorSortOrder({sortOrder: newSortOrder}));
    dispatch(tripMonitorActionCreators.setTripMonitorSortBy({sortBy: followingSortBy}));
};

export const getTravelOrdersBySortOrder = (params: {sortOrder: string}) => async (dispatch, getState) => {
    const {sortOrder: followingSortOrder} = params;

    const state = getState();

    const searchParams = selectors.getTripMonitorSearchParams(state);
    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);
    const tab = selectors.getTripMonitorTab(state);

    const transformedSearchData = transformSearchDataToRequestBody({filters: searchParams});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        orderBy: followingSortOrder,
        sortBy: sorting.sortBy,
        byEntity: toLower(tab),
        page: 1,
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
    dispatch(tripMonitorActionCreators.setTripMonitorSortOrder({sortOrder: followingSortOrder}));
};

export const getTravelOrdersByTab = (params: {tab: string}) => async (dispatch, getState) => {
    const {tab: followingTab} = params;

    const state = getState();

    const searchParams = selectors.getTripMonitorSearchParams(state);
    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);

    const transformedSearchData = transformSearchDataToRequestBody({filters: searchParams});

    const requestBody = {
        ...transformedSearchData,
        byEntity: toLower(followingTab),
        perPage: pagination.perPage,
        orderBy: sorting.sortOrder,
        sortBy: sorting.sortBy,
        page: 1,
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
    dispatch(tripMonitorActionCreators.setTripMonitorTab({tab: followingTab}));
};

export const getTravelOrdersByFilters = (params: {filters: Partial<TripMonitorSearchFormValues>}) => async (
    dispatch,
    getState,
) => {
    const {filters: followingFilters} = params;

    const state = getState();

    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);
    const tab = selectors.getTripMonitorTab(state);

    const transformedSearchData = transformSearchDataToRequestBody({filters: followingFilters});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        orderBy: sorting.sortOrder,
        sortBy: sorting.sortBy,
        byEntity: toLower(tab),
        page: 1,
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
    dispatch(tripMonitorActionCreators.setTripMonitorSearchParams({searchParams: followingFilters}));
};

export const updateTripMonitorTable = () => async (dispatch, getState) => {
    const state = getState();

    const searchParams = selectors.getTripMonitorSearchParams(state);
    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);
    const tab = selectors.getTripMonitorTab(state);

    const transformedSearchData = transformSearchDataToRequestBody({filters: searchParams});

    const requestBody = {
        ...transformedSearchData,
        page: pagination.currentPage,
        perPage: pagination.perPage,
        orderBy: sorting.sortOrder,
        sortBy: sorting.sortBy,
        byEntity: toLower(tab),
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
};

export const getTravelOrdersByClearFiltersForm = () => async (dispatch, getState) => {
    const state = getState();

    const pagination = selectors.getTripMonitorPagination(state);
    const sorting = selectors.getTripMonitorSorting(state);
    const tab = selectors.getTripMonitorTab(state);

    const requestBody = {
        perPage: pagination.perPage,
        orderBy: sorting.sortOrder,
        byEntity: toLower(tab),
        sortBy: sorting.sortBy,
        page: 1,
    };

    const searchResult = await dispatch(getTravelOrders({requestBody}));

    if (!searchResult) {
        return;
    }

    dispatch(tripMonitorActionCreators.travelOrdersReceived({travelOrders: searchResult.items}));
    dispatch(tripMonitorActionCreators.setTripMonitorPagination({pagination: searchResult.pagination}));
    dispatch(tripMonitorActionCreators.setTripMonitorMetaInformation({meta: searchResult.meta || {}}));
    dispatch(tripMonitorActionCreators.setTripMonitorSearchParams({searchParams: {}}));
};

export const setEventTimer = (params: {timerSettings: TimerSettingsFormValues; travelOrderNumber: number}) => async (
    dispatch,
) => {
    const {timerSettings, travelOrderNumber} = params;
    const body = transformEventTimerDataToRequestBody({timerSettings});

    try {
        dispatch(appActions.showLoader());

        const {data} = await toRequests.setTravelOrderEventTimerRequest({
            requestBody: body,
            toNumber: travelOrderNumber,
        });

        dispatch(tripMonitorActionCreators.eventTimerReceived({travelOrderNumber, eventTimer: data?.eventTimer}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const addCheckCall = ({
    travelOrder,
    createFormValue,
}: {
    travelOrder?: TravelOrder;
    createFormValue: AddCheckCallFormValues;
}) => async (dispatch) => {
    const postData = transformAddEventDataToRequestBody(createFormValue);

    const travelOrderNumber = getTravelOrderNumber(travelOrder);

    if (!travelOrderNumber) {
        return;
    }

    try {
        dispatch(appActions.showLoader());

        const listAddedEvents = await locationEventApiGateway.addCheckCallEvent({
            travelOrderNumber,
            postData,
        });

        const {data: freshTravelOrder} = await toRequests.fetchTravelOrder(travelOrderNumber);

        const [event] = listAddedEvents.map((item) => item.event);

        dispatch(
            tripMonitorActionCreators.eventReceived({travelOrderNumber, etaInfo: freshTravelOrder.etaInfo, event}),
        );

        if (event?.eventTimer) {
            dispatch(tripMonitorActionCreators.eventTimerReceived({travelOrderNumber, eventTimer: event.eventTimer}));
        }

        dispatch(closeAll());

        if (createFormValue.summaryItem.isSendToBrokerUpdate && event.id) {
            dispatch(showSendBrokerUpdateModal({eventID: event.id, travelOrderNumber}));
        }
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const sendBrokerUpdate = ({
    eventID,
    travelOrderNumber,
    sendBrokerUpdateData,
}: {
    eventID: string;
    travelOrderNumber: number;
    sendBrokerUpdateData: BrokerUpdateRequestBody;
}) => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const data = await locationEventApiGateway.sendBrokerUpdate(eventID, travelOrderNumber, sendBrokerUpdateData);

        dispatch(tripMonitorActionCreators.addBrokerUpdate({travelOrderNumber, data}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const addLoadNoteToTravelOrder = (params: {newNoteText: string; loadNumber: number}) => async (dispatch) => {
    const {newNoteText, loadNumber} = params;

    try {
        dispatch(appActions.showLoader());

        const {updatedLoad} = await addLoadNotes(loadNumber, {note: newNoteText, isImportant: false});

        dispatch(tripMonitorActionCreators.receivedLoadNoteTravelOrder({load: updatedLoad}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const setTripMonitorAutoEventStatusFilter = (status: AutoCheckCallFilterStatus | null) => (dispatch) => {
    dispatch(tripMonitorActionCreators.setTripMonitorAutoEventStatus({status}));
    dispatch(updateTripMonitorTable());
};

export const handleSSEData = (payload: {data: TripMonitorSSEData[]}) => (dispatch) => {
    const {data} = payload;

    dispatch(tripMonitorActionCreators.setSSEData({data}));
};
