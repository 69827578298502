import {handleSSEData as loadBoardHandleSSEData} from 'pages/LoadBoard/redux/actions';
import {handleSSEData as tripMonitorHandleSSEData} from 'pages/TravelOrders/redux/actions/list';
import {handleSSEData as trucksHandleSSEData} from 'pages/Trucks/redux/actions/list';
import {handleSSEData as trucksNearbyHandleSSEData} from 'pages/TrucksNearby/redux/actions/list';

export const tripMonitorSSEEventHandler = ({dispatch, data}) => {
    dispatch(tripMonitorHandleSSEData({data}));
};

export const trucksNearbySSEEventHandler = ({dispatch, data}) => {
    dispatch(trucksNearbyHandleSSEData({data}));
};

export const truckListSSEEventHandler = ({dispatch, data}) => {
    dispatch(trucksHandleSSEData({data}));
};

export const auctionSSEEventHandler = ({dispatch, data}) => {
    dispatch(loadBoardHandleSSEData({data}));
};
