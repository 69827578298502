import Driver from 'core/entities/Driver/types';

import Pagination from 'types/Pagination';

import * as types from '../actionTypes';
import {LIST_TABS} from '../constants/tabs';

interface ListReducerState {
    list: Driver[];
    pagination: Pagination;
    searchParams?: Record<string, any>;
    activeListTab: keyof typeof LIST_TABS;
}

// exported only for testing purposes
export const defaultState: ListReducerState = {
    list: [],
    pagination: {
        currentPage: 1,
        perPage: 50,
        totalItemsCount: 0,
        pagesCount: 0,
    },
    searchParams: undefined,
    activeListTab: LIST_TABS.GENERAL,
};

export default function drivers(state: ListReducerState = defaultState, action): ListReducerState {
    switch (action.type) {
        case types.DRIVERS_RECEIVED: {
            return {
                ...state,
                list: action.payload.drivers,
                pagination: action.payload.pagination,
            };
        }

        case types.DRIVERS_CLEAR_LIST_ITEMS: {
            return {
                ...state,
                list: [],
                pagination: {...defaultState.pagination},
            };
        }

        case types.DRIVERS_SET_PAGINATION: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload.pagination,
                },
            };
        }

        case types.DRIVERS_SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: {...action.payload},
            };
        }

        case types.DRIVERS_SET_ACTIVE_LIST_TAB: {
            return {
                ...state,
                activeListTab: action.payload,
            };
        }

        case types.DRIVER_ARCHIVED: {
            const {driver: archivedDriver} = action.payload;
            return {
                ...state,
                list: state.list.filter((driver) => driver.id !== archivedDriver.id),
            };
        }

        case types.CLEAR_STATE: {
            return {...defaultState, activeListTab: state.activeListTab};
        }

        default: {
            return state;
        }
    }
}
