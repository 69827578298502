import restapi from 'services/restapi/index';

const apiRoute = '/notes';

/**
 *
 * @param {Object} params
 * @param {string} params.entity_name
 * @param {number} [params.entity_id]
 * @param {CancelToken} cancelToken axios cancel token
 */
export function getGeneralNotes(params, cancelToken = null) {
    return restapi.get(`${apiRoute}?per-page=0`, {params, cancelToken});
}

/**
 *
 * @param {Object} params
 * @param {string} params.entity_name
 * @param {number} params.entity_id
 * @param {string} params.text
 */
export function postGeneralNote(params) {
    return restapi.post(apiRoute, params);
}

export function deleteGeneralNote(noteID) {
    return restapi.delete(`${apiRoute}/${noteID}`);
}

export function deleteOTDGeneralNote(noteID) {
    return restapi.delete(`${apiRoute}/otd/${noteID}`);
}
