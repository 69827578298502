import React from 'react';
import compact from 'lodash/compact';

import useClearSelection from 'pages/Owners/components/modals/SelectCompanies/hooks/useClearSelection';
import useSelectCompaniesState from 'pages/Owners/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import {SELECT_COMPANIES_FORM_NAME} from 'pages/Owners/constants';
import useOwnerFormsNames from 'pages/Owners/hooks/useOwnerFormsNames';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

const SelectCompaniesInfo: React.FC = () => {
    const {onClearSelection} = useClearSelection();
    const {selectCompaniesModal: getName} = useOwnerFormsNames();
    const selectedCompanies = useGetReduxFormValue(SELECT_COMPANIES_FORM_NAME, getName('selectedCompanies'));
    const {allAllianceCount} = useSelectCompaniesState();

    return (
        <div className="mt20">
            <span className={styles.title}>The owner operates for alliance companies:</span>

            <div className="mt10 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap10">
                    <span>
                        All Alliance companies: <strong>{allAllianceCount}</strong>
                    </span>

                    <span className={styles.dot} />

                    <span>
                        Owner work with: <strong>{compact(selectedCompanies)?.length || 0}</strong>
                    </span>
                </div>

                <div className={styles.clear} onClick={onClearSelection}>
                    Clear selection
                </div>
            </div>
        </div>
    );
};

export default SelectCompaniesInfo;
