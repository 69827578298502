import {getOwnerName} from 'core/entities/Owner/modules/ownerName';
import {getOwnerPhone} from 'core/entities/Owner/modules/ownerPhone';
import Owner from 'core/entities/Owner/types';

import {phoneNumberWithBraces} from 'utils';

export const transformOwnersToSelectOptions = (
    owners: Owner[] | null,
    valueAsObject: boolean,
    withoutOwnerDriver?: boolean,
) => {
    if (!owners) {
        return [];
    }

    return owners.map((owner) => ({
        value: valueAsObject ? owner : owner.id,
        label: getOwnerName(owner),
    }));
};

export const transformOwnersWithPhoneLabelToSelectOptions = (owners: Owner[] | null, valueAsObject: boolean) => {
    if (!owners) {
        return [];
    }

    return owners.map((owner) => ({
        value: valueAsObject ? owner : owner.id,
        label: `(ow) ${getOwnerName(owner)} ${phoneNumberWithBraces(getOwnerPhone(owner))}`,
    }));
};
