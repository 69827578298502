import {toast} from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import Truck from 'core/entities/Truck/types';

import notifications from 'components/ui/notifications';

import type {TruckApiGateway, UpdatedTruckResultDTO, UpdateTruckDTO, UploadedFiles} from '../types';
import {isReeferTruck} from '../utils';

const updateTruck = async function (
    data: UpdateTruckDTO,
    deps: {
        truckApiGateway: TruckApiGateway;
        lbsConverter: (units: string | undefined | null, value: number | null | undefined) => number | null;
    },
): Promise<UpdatedTruckResultDTO> {
    const {updatedTruck, teamData, generalNote, addedFiles, deletedFiles, dispatcherInitiator} = data;
    const {owner, firstDriver, secondDriver, mainPerson} = teamData;
    const {truckApiGateway, lbsConverter} = deps;
    const isNeedRestoreBeforeUpdate = updatedTruck.is_deleted;
    const isNeedToDeleteTruckFiles = deletedFiles.length > 0;
    const isNeedToAddTruckFiles = !isEmpty(addedFiles);
    let isRestored = false;

    if (isNeedRestoreBeforeUpdate) {
        await truckApiGateway.restoreTruck(updatedTruck);
        updatedTruck.is_deleted = false;
        isRestored = true;
    }

    if (isNeedToDeleteTruckFiles) {
        await truckApiGateway.removeTruckFiles(deletedFiles);
    }

    if (isNeedToAddTruckFiles) {
        try {
            await truckApiGateway.addTruckFiles({truckID: updatedTruck.id, files: addedFiles as UploadedFiles});

            toast.success(notifications.filesSuccessfulAdded, {data: {}});
        } catch (error) {
            toast.error(notifications.filesUnsuccessfulAdded, {data: {}});
        }
    }

    if (generalNote) {
        await truckApiGateway.addTruckGeneralNote({
            truckID: updatedTruck.id,
            note: generalNote as string,
        });
    }

    const ownerID = owner?.id;
    const firstDriverID = firstDriver?.id || null;
    const secondDriverID = secondDriver?.id || null;
    const cargo_number = updatedTruck?.cargo_number || null;
    const is_reefer = isReeferTruck(updatedTruck);
    const [mainPersonId, mainContactPersonType] = mainPerson.split(' ') as [string, Truck['mainContact']['type']];
    const isOwnerResponsibleForTruck =
        mainContactPersonType === 'ow' || mainContactPersonType === 'o/d' || mainContactPersonType === 'crd';

    const dataForUpdate = {
        ...updatedTruck,
        mainContactPersonId: mainPersonId,
        mainContactPersonType,
        responsible: isOwnerResponsibleForTruck,
        dispatcher: dispatcherInitiator?.id,
        payload: lbsConverter(updatedTruck?.payload_units, updatedTruck.payload),
        gross_weight: lbsConverter(updatedTruck?.payload_units, updatedTruck.gross_weight),
        insurance_expiration: updatedTruck?.insurance_expiration || null,
        owner: ownerID,
        drivers: [firstDriverID, secondDriverID],
        cargo_number,
        is_reefer,
    };

    const updatedTruckData = await truckApiGateway.updateTruck(dataForUpdate as any);

    return {
        updatedTruck: updatedTruckData,
        isRestored,
    };
};

export default updateTruck;
