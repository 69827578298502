import every from 'lodash/every';
import isNull from 'lodash/isNull';
import keyBy from 'lodash/keyBy';

import {SELECT_COMPANIES_FORM_NAME} from 'pages/Owners/constants';
import {SelectCompaniesModalFormValues} from 'pages/Owners/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';
import useGetReduxFormInitialValues from 'hooks/reduxForm/useGetReduxFormInitialValues';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

const useClearSelection = () => {
    const {selectedCompanies: initialCompanies} = useGetReduxFormInitialValues(SELECT_COMPANIES_FORM_NAME);
    const selectedCompanies = useGetReduxFormValue(SELECT_COMPANIES_FORM_NAME, getName('selectedCompanies'));
    const changeFormValues = useChangeReduxFormValues(SELECT_COMPANIES_FORM_NAME);

    const selectedCompaniesIDs = keyBy(initialCompanies, 'value');

    const onClearSelection = () => {
        const filteredCompanies = selectedCompanies.map((company) => {
            if (!company?.value) {
                return null;
            }

            return selectedCompaniesIDs[company.value]?.hasActiveLoad ? company : null;
        });

        const isAllCompaniesCleared = every(filteredCompanies, isNull);
        const resultCompanies = !isAllCompaniesCleared ? filteredCompanies : null;

        changeFormValues({
            [getName('selectedCompanies')]: resultCompanies,
            [getName('isAllCompaniesSelected')]: null,
        });
    };

    return {
        onClearSelection,
    };
};

export default useClearSelection;
