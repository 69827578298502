import {push} from 'connected-react-router';

import * as appActions from 'store/actions';

import {getDriverName} from 'core/entities/Driver/modules/driverName';
import Driver from 'core/entities/Driver/types';
import {getTruckTeam} from 'core/entities/Truck/modules/truckTeam';
import {isTruckStatusOnRoute} from 'core/entities/Truck/statuses';
import Truck from 'core/entities/Truck/types';
import {archiveDriver as archiveDriverApiReq} from 'core/gateways/DriverApiGateway/requests';
import {getTruckByNumber} from 'core/gateways/TruckApiGateway/requests';

import {getDriverLinks} from 'routing/linkBuilder';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {ENTITY_NAME_DRIVER} from 'utils/data/entityNames';

import * as actionCreators from '../actionCreators';
import {modalNames} from '../components/modals/modalMap';
import {LIST_TABS} from '../constants/tabs';
import {ArchivingWarning} from '../types';

import {setActiveListTab} from './list';

const fetchDriverFullData = async (driver: Driver) => {
    const driverTruck = driver.truck;
    const isFreeDriver = !driverTruck;

    if (isFreeDriver) {
        return driver;
    }

    const fullTruckData = await getTruckByNumber(driverTruck?.number).then((response) => response.data);
    const fullDriverData = {...driver, truck: fullTruckData};
    return fullDriverData;
};

export const navigateToUpdatePage = (driver: Driver) => {
    return function (dispatch) {
        const {update: pathToUpdatePage} = getDriverLinks(driver);
        dispatch(push(pathToUpdatePage));
    };
};

const navigateToListPage = (driver: Driver) => {
    return function (dispatch) {
        const {list: pathToListPage} = getDriverLinks(driver);
        dispatch(push(pathToListPage));
    };
};

const waitConfirmArchivingWhenDriverIsFree = ({driver, dispatch}: {driver: Driver; dispatch}) => {
    return new Promise((resolve) => {
        const warningType: ArchivingWarning = 'generalDriverArchiving';
        const openModalAction = modalActions.openModal({
            modalName: modalNames.archivingWarn,
            data: {
                warningType,
                driver,
            },
            handlers: {
                onCancel: () => resolve(false),
                onConfirm: () => resolve(true),
            },
        });
        dispatch(openModalAction);
    });
};
const showArchivingErrorWhenDriverTruckOnRoute = ({
    driver,
    truck,
    dispatch,
}: {
    driver: Driver;
    truck: Truck;
    dispatch;
}) => {
    return new Promise((resolve) => {
        const openModalAction = modalActions.openModal({
            modalName: modalNames.archivingError,
            data: {
                driver,
                truck,
            },
            handlers: {
                leftButtonHandler: () => resolve(false),
                rightButtonHandler: () => resolve(true),
            },
        });
        dispatch(openModalAction);
    });
};

const waitConfirmArchivingWithFirstDriver = ({
    driver,
    isDriverMain,
    truck,
    dispatch,
}: {
    driver: Driver;
    isDriverMain: boolean;
    truck: Truck;
    dispatch;
}) => {
    return new Promise((resolve) => {
        const warningType: ArchivingWarning = 'firstDriverArchiving';
        const openModalAction = modalActions.openModal({
            modalName: modalNames.archivingWarn,
            data: {
                warningType,
                driver,
                isDriverMain,
                truck,
            },
            handlers: {
                onCancel: () => resolve(false),
                onConfirm: () => resolve(true),
            },
        });
        dispatch(openModalAction);
    });
};

const confirmArchiving = (driverForArchive: Driver, dispatch) => {
    const driverTruck = driverForArchive.truck;

    if (!driverTruck) {
        return waitConfirmArchivingWhenDriverIsFree({driver: driverForArchive, dispatch});
    }

    const isDriverAttachedToOnRouteTruck = isTruckStatusOnRoute(driverTruck.status);

    if (isDriverAttachedToOnRouteTruck) {
        return showArchivingErrorWhenDriverTruckOnRoute({
            driver: driverForArchive,
            truck: driverTruck,
            dispatch,
        });
    }

    const {firstDriver, isDriverResponsible: isFirstDriverMainContact} = getTruckTeam(driverTruck);
    const archivedDriverIsFirstDriver = firstDriver?.id === driverForArchive.id;

    if (archivedDriverIsFirstDriver && isFirstDriverMainContact) {
        return waitConfirmArchivingWithFirstDriver({
            driver: driverForArchive,
            isDriverMain: true,
            truck: driverTruck,
            dispatch,
        });
    }

    if (archivedDriverIsFirstDriver && !isFirstDriverMainContact) {
        return waitConfirmArchivingWithFirstDriver({
            driver: driverForArchive,
            isDriverMain: false,
            truck: driverTruck,
            dispatch,
        });
    }

    return waitConfirmArchivingWhenDriverIsFree({driver: driverForArchive, dispatch});
};

const showSuccessArchiving = (driver: Driver) => {
    return function (dispatch) {
        const driverTruck = driver.truck;
        const truckTeam = driverTruck ? getTruckTeam(driverTruck) : {};
        const isFirstDriver = truckTeam.firstDriver?.id === driver.id || false;
        const isDriverMain = truckTeam.isDriverResponsible || false;
        const viewArchivedDriversAction = () => {
            dispatch(navigateToListPage(driver));
            dispatch(setActiveListTab(LIST_TABS.ARCHIVED));
            dispatch(modalActions.closeAll());
        };

        // order actions on close all current actions is important!
        // we should close all current modals before open success restore
        dispatch(modalActions.closeAll());

        const successModalAction = modalActions.openModal({
            modalName: modalNames.archivingSuccess,
            data: {
                driver,
                isFirstDriver,
                isDriverMain,
                truck: driverTruck,
            },
            handlers: {
                onViewArchivedList: viewArchivedDriversAction,
            },
        });

        dispatch(successModalAction);
    };
};

const confirmRestore = (driver: Driver, dispatch) => {
    return new Promise((resolve) => {
        const openModalAction = modalActions.openModal({
            modalName: commonModalNames.informationModal,
            data: {
                title: 'Restore Driver',
                toProfileLabel: getDriverName(driver),
                entityName: ENTITY_NAME_DRIVER,
                leftButtonTitle: 'KEEP',
                rightButtonTitle: 'Restore',
                bodyType: 'RestoreForm',
                buttonType: 'danger',
            },
            handlers: {
                leftButtonHandler: () => resolve(false),
                rightButtonHandler: () => resolve(true),
            },
        });
        dispatch(openModalAction);
    });
};

export function archiveDriver(driver: Driver): any {
    return async function (dispatch) {
        try {
            dispatch(appActions.showLoader());

            const fullDriverData = await fetchDriverFullData(driver);

            dispatch(appActions.hideLoader());

            const isConfirmed = await confirmArchiving(fullDriverData, dispatch);

            if (!isConfirmed) {
                return;
            }

            dispatch(appActions.showLoader());

            const archivedDriver = await archiveDriverApiReq(fullDriverData);

            if (!archivedDriver) {
                return;
            }

            dispatch(actionCreators.archiveDriver(archivedDriver));
            dispatch(showSuccessArchiving(archivedDriver));
        } catch (e) {
            dispatch(appActions.handleError(e));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

export function restoreDriver(driver: Driver): any {
    return async function (dispatch) {
        const isConfirmed = await confirmRestore(driver, dispatch);
        if (!isConfirmed) {
            return;
        }
        dispatch(navigateToUpdatePage(driver));
    };
}
