import Owner from 'core/entities/Owner/types';

import {phoneNumberWithBraces} from 'utils';

export const getOwnerCoordinator = (owner?: Owner | null): Owner | undefined => {
    if (!owner || !owner?.owner_with_coordinator) {
        return undefined;
    }

    return owner;
};

type CoordinatorAsOption = {label: string; value: any} | null;
export const getOwnerCoordinatorAsOption = (owner?: Owner | null): CoordinatorAsOption => {
    const {coordinator_full_name: coordName, coordinator_mobile_phone: coordPhone} = owner || {};

    if (coordName && coordPhone) {
        return {label: `(crd) ${coordName} ${phoneNumberWithBraces(coordPhone)}`, value: 1};
    }

    return null;
};
