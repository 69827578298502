import React from 'react';

import {getTruckNumber} from 'core/entities/Truck/modules/common/getters/getTruckNumber';

import useTruckWorkWithCompaniesState from 'pages/TrucksNearby/components/common/modals/WorkWithCompanies/hooks/useTruckWorkWithCompaniesState';

const CompaniesTableEmpty: React.FC = () => {
    const {truck} = useTruckWorkWithCompaniesState();

    const truckNumber = getTruckNumber(truck);

    return (
        <tr>
            <td colSpan={4}>
                <div className="mt15 mb20 text-center">
                    There are no companies that the Truck #{truckNumber} works with
                </div>
            </td>
        </tr>
    );
};

export default CompaniesTableEmpty;
