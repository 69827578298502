import {CurrentTruck} from 'pages/Trucks/types';

export const handleReducerByTruckReceived = (params: {state; truckData: CurrentTruck}) => {
    const {state, truckData} = params;

    return {
        ...state,
        truckData,
    };
};
