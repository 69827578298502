import Truck from 'core/entities/Truck/types';

import {
    COMMON_TRUCK_RESERVE_UPDATED,
    CROSS_SERVER_TRUCK_RESERVATION_CANCELED,
    CROSS_SERVER_TRUCK_RESERVATION_COMPLETED,
} from '../actionTypes';

export const completeCrossServerTruckReservation = (payload) => ({
    type: CROSS_SERVER_TRUCK_RESERVATION_COMPLETED,
    payload,
});

export const cancelCrossServerTruckReservation = (payload) => ({
    type: CROSS_SERVER_TRUCK_RESERVATION_CANCELED,
    payload,
});

type TruckReserveUpdatedPayload = {
    id: string;
    reserve: Partial<Truck['reserve']>;
};
export const commonTruckReserveUpdated = (payload: TruckReserveUpdatedPayload) => ({
    type: COMMON_TRUCK_RESERVE_UPDATED,
    payload,
});
