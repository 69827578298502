import Load from 'deprecated/core/entities/Load/types';

import Owner from 'core/entities/Owner/types';
import Truck from 'core/entities/Truck/types';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import Carrier from 'core/entities/Carrier/types';

import Rate from './Rate';
import Dispatch from './Dispatch';
import DryRun from './DryRun';
import LocationEvent from './LocationEvent';
import Step from './Step';
import {Bol} from './Bol';

type RateConfirmationNotes = {
    service_requirements: string[];
    for_payment: string[];
};

interface TravelOrder {
    id: number;
    empty_miles: number;
    is_confirmed: boolean;
    is_planned: boolean;
    finished_datetime: string;
    finished_timezone: string;
    status: string;
    step_number: number;
    carrier: Carrier | null;
    send_robinson_coordinates: boolean;
    // carrier_settlement: nullableType(ts.unknown),
    dispatch: Dispatch | null;
    needs_redispatch: boolean | null;
    dispatcher: Dispatcher;
    dry_run: DryRun | null;
    finished_dispatcher: Dispatcher;
    load: Load;
    location_events: LocationEvent[];
    // owner_settlement: nullableType(ts.unknown),
    // planned_to_count: nullableType(ts.unknown),
    rate: Rate | null;
    // step: nullableType(ts.unknown),
    // timers: ts.array(ts.unknown),
    truck: Truck;
    step: Step;
    temp_control_bol: Bol | null;
    rate_confirmation_notes?: RateConfirmationNotes;
    truck_owner: Owner | null;
    changed_owner: {
        travelOrderOwner: {id: string; name: string};
        truckOwner: {id: string; name: string};
    } | null;
}

export const getEntityFieldName = (name: keyof TravelOrder): string => name;

export default TravelOrder;
