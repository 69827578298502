import React from 'react';
import classNames from 'classnames';
import {Field} from 'redux-form';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import SortingIcon from 'components/ui/SortingIcon';

import useSelectCompaniesActions from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSelectCompaniesActions';
import useSelectCompaniesState from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import useSyncTableRows from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSyncTableRows';
import {SELECT_COMPANIES_FORM_NAME} from 'pages/Trucks/constants';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';

import SelectCompaniesTableRow from './components/SelectCompaniesTableRow';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    truckForm: string;
};

const SelectCompaniesTable: React.FC<OwnProps> = (props) => {
    const {truckForm} = props;

    const {companiesForSelectionAll, companiesForClearAll} = useSyncTableRows({fromInitial: true, truckForm});
    const changeFormValues = useChangeReduxFormValues(SELECT_COMPANIES_FORM_NAME);
    const {companies, searchParams} = useSelectCompaniesState();
    const {getCompaniesBySorting} = useSelectCompaniesActions();

    const handleSortingClick = () => {
        getCompaniesBySorting();
    };

    const handleCheckBoxClick = (value: boolean) => {
        if (value) {
            changeFormValues({[getName('selectedCompanies')]: companiesForSelectionAll});
        } else {
            changeFormValues({
                [getName('selectedCompanies')]: companiesForClearAll,
                [getName('isAllCompaniesSelected')]: null,
            });
        }

        return value;
    };

    const filteredCompanies = (companies || []).filter(({company_name}) => {
        if (!searchParams?.companyName) {
            return true;
        }

        const lowerCasedName = company_name.toLowerCase();
        const lowerCasedNameFilter = searchParams.companyName.toLowerCase();

        return lowerCasedName.includes(lowerCasedNameFilter);
    });

    const renderCompanies = filteredCompanies.map((company, index) => (
        <SelectCompaniesTableRow key={company.id} company={company} index={index} truckForm={truckForm} />
    ));

    return (
        <div className={classNames(styles.table, styles.scroll, 'standard-table-style')}>
            <table className="table mb0">
                <thead>
                    <tr>
                        <th>
                            <div className="d-flex">
                                <Field
                                    formatValueBeforeChange={handleCheckBoxClick}
                                    name={getName('isAllCompaniesSelected')}
                                    component={CheckBoxInput}
                                    position="right"
                                />
                                <SortingIcon
                                    sortListName="selectCompaniesModal"
                                    onClick={handleSortingClick}
                                    sortBy="company_name"
                                    title="COMPANY NAME"
                                    sortType="alpha"
                                />
                            </div>
                        </th>

                        <th>PREFIX</th>

                        <th>PHYSICAL ADDRESS</th>

                        <th>MC#</th>
                    </tr>
                </thead>

                <tbody className="table-body-bordered">{renderCompanies}</tbody>
            </table>
        </div>
    );
};

export default SelectCompaniesTable;
