import React from 'react';
import classNames from 'classnames';

import {getLoadLastRCFile} from 'core/entities/Load/modules/loadFiles';
import {getRefNumbersList} from 'core/entities/Load/modules/loadRefNumber';
import Load from 'core/entities/Load/types';

import Tooltip from 'components/ui/Tooltip';

import getFilePath from 'utils/files/getFilePath';
import getText from 'utils/getText';

type OwnProps = {
    withTooltip?: boolean;
    isDisabled?: boolean;
    withLink?: boolean;
    load: Load;
};

const RefNumberLink: React.FC<OwnProps> = (props) => {
    const {load, withTooltip = true, withLink = true, isDisabled = false} = props;

    const [firstRefNumber] = getRefNumbersList(load);
    const lastRcFile = getLoadLastRCFile(load);
    const noRCFileText = getText('noRcFile');

    const mainClassNames = classNames('second-link', 'ellips-text', {disabled: isDisabled || !lastRcFile});

    const openWindowWithRCFile = (): void => {
        const win = window.open('', '_blank');

        if (win !== null) {
            win.location.href = getFilePath(lastRcFile);
        }
    };

    if (withLink && withTooltip && !isDisabled && !lastRcFile) {
        return (
            <Tooltip
                className="d-inline-block align-bottom icon-tooltip"
                tooltipContent={
                    <span>
                        #<strong>{firstRefNumber}</strong>
                        <br />
                        <em>{noRCFileText}</em>
                    </span>
                }
            >
                <div onClick={openWindowWithRCFile} className={mainClassNames}>
                    #<strong>{firstRefNumber}</strong>
                </div>
            </Tooltip>
        );
    }

    if (withLink) {
        return (
            <span onClick={openWindowWithRCFile} className={mainClassNames}>
                #<strong>{firstRefNumber}</strong>
            </span>
        );
    }

    return (
        <div className="ellips-text">
            #<strong>{firstRefNumber}</strong>
        </div>
    );
};

export default RefNumberLink;
