import appConfig from 'config';

import {ROUTES_PATHS} from 'routing/constants';
import {RoutesNames} from 'routing/types';

import * as handlers from './handlers';

export const handlersMap: Partial<Record<RoutesNames, any>> = {
    [ROUTES_PATHS.trucksNearby]: handlers.trucksNearbySSEEventHandler,
    [ROUTES_PATHS.tripMonitor]: handlers.tripMonitorSSEEventHandler,
    [ROUTES_PATHS.trucksList]: handlers.truckListSSEEventHandler,
    [ROUTES_PATHS.auction]: handlers.auctionSSEEventHandler,
};

const sseChannels = {
    trip_monitor: `trip_monitor_${appConfig.REACT_APP_CLUSTER}_${appConfig.REACT_APP_PREFIX}`,
    trucks: 'trucks',
};

export const pathsMap = {
    [ROUTES_PATHS.tripMonitor]: sseChannels.trip_monitor,
    [ROUTES_PATHS.trucksNearby]: sseChannels.trucks,
    [ROUTES_PATHS.trucksList]: sseChannels.trucks,
    [ROUTES_PATHS.auction]: sseChannels.trucks,
};
