import Owner from 'core/entities/Owner/types';

import Pagination from 'types/Pagination';

export const SET_OWNER_FIELDS_FOR_DRIVER = 'OWNER/CONTAINER_SET_OWNER_FIELDS_FOR_DRIVER' as const;
export const TOGGLE_RESET_FORM_MODAL = 'OWNER/CONTAINER_TOGGLE_RESET_FORM_MODAL' as const;
export const OWNERS_SET_CURRENT_PAGE = 'OWNER/COMPONENT_SET_CURRENT_PAGE_COUNT' as const;
export const OWNERS_SET_SEARCH_PARAMS = 'OWNER/COMPONENT_SET_SEARCH_PARAMS' as const;
export const DISPATCHERS_RECEIVED = 'OWNER/CONTAINER_DISPATCHERS_RECEIVED' as const;
export const TOGGLE_CREATE_MODAL = 'OWNER/CONTAINER_TOGGLE_CREATE_MODAL' as const;
export const OWNER_GENERAL_NOTE_RECEIVED = 'OWNER/GENERAL_NOTE_RECEIVED' as const;
export const TOGGLE_UPDATE_MODAL = 'OWNER/CONTAINER_TOGGLE_UPDATE_MODAL' as const;
export const OWNER_GENERAL_NOTE_DELETED = 'OWNER/GENERAL_NOTE_DELETED' as const;
export const OWNERS_RECEIVED = 'OWNER/COMPONENT_OWNERS_DATA_RECEIVED' as const;
export const OWNERS_SET_PAGINATION = 'OWNER/COMPONENT_SET_PAGINATION' as const;
export const FORM_TAB_CHANGE = 'OWNER/CONTAINER_FORM_TAB_CHANGE' as const;
export const SET_ACTIVE_LIST_TAB = 'OWNER/SET_ACTIVE_LIST_TAB' as const;
export const OWNER_ARCHIVED = 'OWNER/COMPONENT_OWNER_ARCHIVED' as const;
export const CLEAR_STATE = 'OWNER/CONTAINER_CLEAR_STATE' as const;
export const OWNER_RECEIVED = 'OWNER/RECEIVED' as const;

export const OWNER_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED = 'OWNER/COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED' as const;
export const OWNER_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED = 'OWNER/COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED' as const;
export const OWNER_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED = 'OWNER/COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED' as const;
export const OWNER_COMPANIES_TAB_ITEMS_RECEIVED = 'OWNER/OWNER_COMPANIES_TAB_ITEMS_RECEIVED' as const;

type Action<T> = {
    type: string;
    payload?: T;
};
export type ReceiveOwners = Action<{
    data: Owner[];
    pagination: Pagination;
}>;

export type ArchivedOwner = Action<{
    owner: Owner;
}>;

export type SetSearchParams = Action<{
    [key: string]: any;
}>;

export type SetCurrentPage = Action<number>;
export type SetPagination = Action<{
    currentPage: number;
    perPage: number;
}>;
