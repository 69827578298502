import React, {useCallback, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';

import {AppState} from 'store';

import Driver from 'core/entities/Driver/types';
import Owner from 'core/entities/Owner/types';
import {fetchOwner} from 'core/gateways/OwnerApiGateway/requests';

import Button from 'components/ui/Buttons/Button';
import {TruckLink} from 'components/ui/Links';

import * as formActions from 'pages/Drivers/actions/form';
import {FIELDS} from 'pages/Drivers/constants/fields';
import {getCurrentDriver} from 'pages/Drivers/selectors';
import checkDriverOnTruck from 'pages/Drivers/utils/checkDriverOnTruck';

import Form from './Form';

import styles from './styles.module.scss';

interface OwnProps {
    data: {
        driverFormName: string;
    };
    onClose();
}

export interface OwnerSettings {
    driverIsOwner: boolean;
    owner: {label: string; value: Owner} | null;
}

interface StateProps {
    driverIsOwner: boolean;
    ownerOfDriver: Owner;
    driver: Driver;
}

interface DispatchProps {
    chooseOwner(ownerSettings: OwnerSettings, formName: string): void;
}

const generalFields = FIELDS.general;

const ChooseOwnerModal = (props: OwnProps & DispatchProps & StateProps) => {
    const {
        onClose,
        chooseOwner,
        data: {driverFormName},
        driverIsOwner,
        ownerOfDriver,
        driver,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [initialOwner, setInitialOwner] = useState<Owner | null>(null);

    const initialValues: OwnerSettings = {
        driverIsOwner: Boolean(driverIsOwner),
        owner: driver?.is_deleted || !initialOwner ? null : {label: initialOwner.full_name, value: initialOwner},
    };

    useEffect(() => {
        if (ownerOfDriver?.id) {
            setIsLoading(true);
            fetchOwner(ownerOfDriver.id)
                .then((res) => {
                    if (res.data) {
                        setInitialOwner(res.data);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });

            return;
        }

        setInitialOwner(null);
    }, [ownerOfDriver?.id]);

    const handleSubmit = useCallback(function (fields: OwnerSettings): void {
        chooseOwner(fields, driverFormName);
        onClose();
    }, []);

    const truckNumber = `Truck #${driver?.truck?.number}`;
    const driverOnTruck = checkDriverOnTruck(driver?.truck, driver);

    return (
        <Modal show={true} onHide={onClose} dialogClassName="standard-form-modal" backdrop="static" size="sm">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Settings</Modal.Title>

                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            {driverOnTruck?.isMainPerson || driverOnTruck?.isOnlyFirstDriver ? (
                <>
                    <Modal.Body>
                        <div className={styles.error}>
                            <div className="row">
                                <div className="col-1 text-center">
                                    <i className="fa fa-exclamation" />
                                </div>
                                <div className="col-11">
                                    <h2>Attention</h2>
                                    <p>
                                        This driver is the first driver{' '}
                                        {driverOnTruck.isMainPerson && `and main contact person `}
                                        on{' '}
                                        <strong>
                                            <TruckLink
                                                truck={driver.truck}
                                                content={truckNumber}
                                                className="second-link"
                                            />
                                            .
                                        </strong>
                                    </p>
                                    <p>
                                        At first, change the{' '}
                                        {driverOnTruck.isMainPerson ? `main contact person ` : `first driver `}on truck
                                        #{driver?.truck?.number}.
                                    </p>
                                    <p>Then you can return to the driver settings.</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div />
                        <Button onClick={onClose} buttonSize="small" colorTheme="grey">
                            Ok
                        </Button>
                    </Modal.Footer>
                </>
            ) : (
                <Modal.Body>
                    <Form
                        driverFormName={driverFormName}
                        initialValues={initialValues}
                        isFormDisabled={isLoading}
                        // @ts-ignore
                        onSubmit={handleSubmit}
                        onClose={onClose}
                    />
                </Modal.Body>
            )}
        </Modal>
    );
};

const mapStateToProps = (state: AppState, ownProps): StateProps => {
    const driverIsOwner = formValueSelector(ownProps.data.driverFormName)(state, generalFields.is_owner);
    const ownerOfDriver = formValueSelector(ownProps.data.driverFormName)(state, generalFields.owner);
    const driver = getCurrentDriver(state);
    return {
        driverIsOwner,
        ownerOfDriver,
        driver,
    };
};

const dispatchToProps = (dispatch): DispatchProps => ({
    chooseOwner: (ownerSettings, formDriverName) => dispatch(formActions.chooseOwner(formDriverName, ownerSettings)),
});

export default connect(mapStateToProps, dispatchToProps)(ChooseOwnerModal);
