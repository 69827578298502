import {isEmpty, isString} from 'lodash';

export const getServerIDsByRegion = (params: {
    HR_SERVER_ID_EU: string | null;
    HR_SERVER_ID_NA: string | null;
    SERVER_IDS_EU: string | null;

    serverIds: string[];
}): string[] => {
    const {serverIds, HR_SERVER_ID_EU, HR_SERVER_ID_NA, SERVER_IDS_EU} = params;

    if (isEmpty(serverIds)) {
        return [];
    }

    const euServerIDS = (SERVER_IDS_EU || '').split(',');
    const isUserFromEU = serverIds.find((item) => euServerIDS.find((itm) => itm === item));

    return [...serverIds, isUserFromEU ? HR_SERVER_ID_EU : HR_SERVER_ID_NA].filter(isString);
};
