import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';

import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import {getTruckReserveInfo} from 'core/entities/Truck/modules/truckReserve';

import Button from 'components/ui/Buttons/Button';

import withTruckReservingTimer from 'hocs/withTruckReservingTimer';
import {IsTruckReservedContext} from 'hocs/withTruckReservingTimer/IsTruckReservedContext';

import TruckNotes from '../../TruckNotes';

import ReservingForm from './components/ReservingForm';
import ReservingInfo from './components/ReservingInfo';

import * as actions from './actions';
import * as types from './actionTypes';
import {RESERVATION_DEFAULT_VALUE, reserveActions} from './constants';

import './style.scss';

class TruckReserving extends React.Component<any, any> {
    componentDidMount() {
        const {truck, setTruckId} = this.props;
        setTruckId(truck.id);
    }

    componentWillUnmount() {
        this.props.clearState();
    }

    onReserveFormSubmit = (values) => {
        const {truck, currentDispatcher, isCloseOnlyReserveModalOnSuccess, onClose} = this.props;

        this.props.reserveTruck({
            ...values,
            isCloseOnlyReserveModalOnSuccess,
            currentDispatcher,
            onClose,
            truck,
        });
    };

    onClose = () => {
        this.props.onClose(this.props.truck);
    };

    onCancelReserve = () => {
        const {truck, isCloseOnlyReserveModalOnSuccess, onClose} = this.props;
        this.props.onCancelReserve({truck, isCloseOnlyReserveModalOnSuccess, onClose});
    };

    render() {
        const {
            isDisableDispatcherLinks,
            isDisableReservedByLink,
            isCrossServerReserving,
            isNeedDispatcherEmail,
            isDisableDriverLinks,
            isNeedGeneralNotes,
            currentDispatcher,
            truck,
        } = this.props;

        return (
            <IsTruckReservedContext.Consumer>
                {(isReserved) => {
                    const {reservedById, waitingRC, reservedByEmail} = getTruckReserveInfo(truck) || {};

                    const reserveFormInitial = {
                        reserveAction: waitingRC ? reserveActions.holdForRC : reserveActions.reserveForTime,
                        reservationMinutes: RESERVATION_DEFAULT_VALUE,
                        waitingRC,
                    };

                    const reservedByCurrent = isCrossServerReserving
                        ? reservedByEmail === currentDispatcher.email
                        : reservedById === currentDispatcher.id;

                    const reserveType = {
                        reserved: isReserved || waitingRC,
                        notReserved: !isReserved && !waitingRC,
                        reservedByCurrent: (isReserved && reservedByCurrent) || (waitingRC && reservedByCurrent),
                        reservedByAnother: (isReserved && !reservedByCurrent) || (waitingRC && !reservedByCurrent),
                    };

                    const classStep = reserveType.notReserved || reserveType.reservedByCurrent ? 'step1' : '';

                    return (
                        <Modal
                            show={true}
                            className={`standard-form-modal truck-reserving-modal ${classStep}`}
                            backdrop="static"
                        >
                            <Modal.Header>
                                <div className="modal-header__top">
                                    <h4 className="modal-title">
                                        Truck #{truck.number}
                                        <div className="modal-subtitle">reservation menu</div>
                                    </h4>
                                    <button className="close" onClick={this.onClose} type="button">
                                        <i className="fa fa-times" aria-hidden="true" />
                                    </button>
                                </div>
                            </Modal.Header>

                            <Modal.Body>
                                <ReservingInfo
                                    isDisableReservedByLink={isDisableReservedByLink}
                                    isCrossServerReserving={isCrossServerReserving}
                                    isNeedDispatcherEmail={isNeedDispatcherEmail}
                                    currentDispatcher={currentDispatcher}
                                    currentReserve={truck.reserve}
                                    reserveType={reserveType}
                                    waitingRC={waitingRC}
                                />

                                <TruckNotes
                                    isDisableDispatcherLinks={isCrossServerReserving || isDisableDispatcherLinks}
                                    isDisableDriverLinks={isDisableDriverLinks}
                                    isNeedGeneralNotes={isNeedGeneralNotes}
                                    onlyViewNotes={true}
                                    withTitle={true}
                                    truck={truck}
                                />

                                {reserveType.reservedByCurrent || reserveType.notReserved ? (
                                    <ReservingForm
                                        isCrossServerReserving={isCrossServerReserving}
                                        onCancelReserve={this.onCancelReserve}
                                        onSubmit={this.onReserveFormSubmit}
                                        initialValues={reserveFormInitial}
                                        form="reserving_modal_form"
                                        reserveType={reserveType}
                                        onCancel={this.onClose}
                                        truck={truck}
                                    />
                                ) : null}
                            </Modal.Body>

                            {reserveType.reservedByAnother && (
                                <Modal.Footer>
                                    <Button buttonSize="small" colorTheme="grey" onClick={this.onClose}>
                                        CANCEL
                                    </Button>
                                </Modal.Footer>
                            )}
                        </Modal>
                    );
                }}
            </IsTruckReservedContext.Consumer>
        );
    }
}

export default connect(
    (state: any, ownProps: any) => {
        const truck = state.truckReserveModal.truckUpdatedByWebSocket || ownProps.data.truck;

        return {
            truck,
            currentDispatcher: getCurrentDispatcher(state),
            isNeedGeneralNotes: ownProps.data.isNeedGeneralNotes,
            isDisableDriverLinks: ownProps.data.isDisableDriverLinks,
            isNeedDispatcherEmail: ownProps.data.isNeedDispatcherEmail,
            isDisableDispatcherLinks: ownProps.data.isDisableDriverLinks,
            isCrossServerReserving: ownProps.data.isCrossServerReserving,
            isDisableReservedByLink: ownProps.data.isDisableReservedByLink,
            isCloseOnlyReserveModalOnSuccess: ownProps.data.isCloseOnlyReserveModalOnSuccess,
        };
    },
    (dispatch: any, ownProps: any) => ({
        onCancelReserve: (params) =>
            dispatch(
                actions.cancelReservation({...params, isCrossServerReserving: ownProps.data.isCrossServerReserving}),
            ),
        reserveTruck: (params) =>
            dispatch(
                actions.reserve({
                    ...params,
                    isCrossServerReserving: ownProps.data.isCrossServerReserving,
                }),
            ),
        clearState: () => dispatch({type: types.CLEAR_STATE}),
        setTruckId: (id) => dispatch(actions.setTruckId(id)),
    }),
)(withTruckReservingTimer(TruckReserving));
