import Truck from 'core/entities/Truck/types';

import type {CreatedTruckResultDTO, CreateTruckDTO, TruckApiGateway, TruckGeneralNote} from '../types';
import {isReeferTruck} from '../utils';

const createTruck = async function (
    data: CreateTruckDTO,
    deps: {
        lbsConverter: (units: string | undefined | null, value: number | null | undefined) => number | null;
        truckApiGateway: TruckApiGateway;
    },
): Promise<CreatedTruckResultDTO> {
    const {truckData, teamData, dispatcherInitiator, generalNote} = data;
    const {lbsConverter, truckApiGateway} = deps;
    const {owner, firstDriver, secondDriver, mainPerson} = teamData;
    const ownerID = owner?.id;
    const firstDriverID = firstDriver?.id || null;
    const secondDriverID = secondDriver?.id || null;
    const cargo_number = truckData?.cargo_number || null;
    const is_reefer = isReeferTruck(truckData);
    const [mainPersonId, mainContactPersonType] = mainPerson.split(' ') as [string, Truck['mainContact']['type']];
    const isOwnerResponsibleForTruck =
        mainContactPersonType === 'ow' || mainContactPersonType === 'o/d' || mainContactPersonType === 'crd';

    const dataForCreate = {
        ...truckData,
        mainContactPersonId: mainPersonId,
        mainContactPersonType,
        responsible: isOwnerResponsibleForTruck,
        dispatcher: dispatcherInitiator?.id,
        payload: lbsConverter(truckData?.payload_units, truckData.payload),
        gross_weight: lbsConverter(truckData?.payload_units, truckData.gross_weight),
        owner: ownerID,
        drivers: [firstDriverID, secondDriverID],
        cargo_number,
        is_reefer,
    };

    const createdTruck = await truckApiGateway.createTruck(dataForCreate);
    const createdGeneralNote = generalNote
        ? await truckApiGateway.addTruckGeneralNote({truckID: createdTruck.id, note: generalNote as string})
        : undefined;

    return {createdTruck, createdTruckGeneralNote: createdGeneralNote as TruckGeneralNote | undefined};
};

export default createTruck;
