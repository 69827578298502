import restApi from 'services/restapi';

export const getTrucksNearbyRequest = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby', requestPayload);
};

export const getTruckCompaniesRequest = ({truckID, requestPayload}) => {
    return restApi.post(`/trucks/trucks-nearby/${truckID}/companies`, requestPayload);
};

export const generateTrucksReportRequest = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby/export', requestPayload);
};

export const postQuoteSmsRequest = ({requestPayload}) => {
    return restApi.post('/messages/mass', requestPayload);
};

export const getLatestPartnerTruckDataRequest = ({truckID}) => {
    return restApi.get(`/trucks/trucks-nearby/truck/${truckID}`);
};

export const searchTrucksByIDRequest = ({requestPayload}) => {
    return restApi.post('/trucks/trucks-nearby/truck/search', requestPayload);
};
