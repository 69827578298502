import * as types from '../actionTypes';

interface ModalsReducerState {
    isRestoreAccount: boolean;
}

export const defaultState: ModalsReducerState = {
    isRestoreAccount: true,
};

export default function modals(state: ModalsReducerState = defaultState, action): ModalsReducerState {
    switch (action.type) {
        case types.SET_IS_RESTORE_DRIVER_ACCOUNT: {
            return {
                ...state,
                isRestoreAccount: action.payload,
            };
        }

        case types.CLEAR_DRIVER_STATE: {
            return defaultState;
        }

        default: {
            return state;
        }
    }
}
