import Owner from 'core/entities/Owner/types';

import {SearchFilterValues} from 'pages/Owners/types/filter';
import {SelectCompaniesModalFormValues} from 'pages/Owners/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

const useOwnerFormsNames = () => ({
    owner: getTypeFieldNameFactory<Owner>(),
    filterSearchForm: getTypeFieldNameFactory<SearchFilterValues>(),
    selectCompaniesModal: getTypeFieldNameFactory<SelectCompaniesModalFormValues>(),
});

export default useOwnerFormsNames;
