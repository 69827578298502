import {DispatcherNoteApi} from 'core/entities/Truck/types/Note';

import {ListState} from 'pages/TrucksNearby/redux/reducers/list';

export const handleReducerByDispatcherNoteReceived = (params: {
    state: ListState;
    truckID: string;
    noteApiResponse: DispatcherNoteApi;
}) => {
    const {state, truckID, noteApiResponse} = params;

    const items = state.items.map((item) => (`${item.id}` === truckID ? {...item, ...noteApiResponse} : item));

    return {
        ...state,
        items,
    };
};
