import {checkDriverLicenseExpiration} from 'core/entities/Driver/modules/driverLicense/checkDriverLicenseExpiration';
import {checkTruckInsuranceExpiration} from 'core/entities/Truck/modules/truckInsurance/checkTruckInsuranceExpiration';

export const getExpiredDocumentsList = (formValues) => {
    const {insurance_expiration: insuranceExpiration} = formValues;
    const invalidDocuments: string[] = [];

    if (!insuranceExpiration || checkTruckInsuranceExpiration(insuranceExpiration).isExpired) {
        invalidDocuments.push('Truck insurance');
    }

    const {firstDriverOption, secondDriverOption} = formValues || {};
    const {value: firstDriver} = firstDriverOption || {};
    const {value: secondDriver} = secondDriverOption || {};

    if (firstDriver && (!firstDriver.expiration || checkDriverLicenseExpiration(firstDriver.expiration).isExpired)) {
        invalidDocuments.push('Driver license');
    }

    if (secondDriver && (!secondDriver.expiration || checkDriverLicenseExpiration(secondDriver.expiration).isExpired)) {
        invalidDocuments.push('2nd driver license');
    }

    return invalidDocuments;
};
