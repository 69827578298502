import type {AxiosPromise} from 'axios';
import head from 'lodash/head';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';

import * as appActions from 'store/actions';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/reducers/sort/selectors';

import Driver from 'core/entities/Driver/types';
import {revealDriverHiddenData, searchDrivers} from 'core/gateways/DriverApiGateway/requests';

import {LIST_TABS} from 'pages/Drivers/constants/tabs';

import formatPaginationParams from 'utils/formatPaginationParams';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import Pagination from 'types/Pagination';

import * as types from '../actionTypes';
import {getDriverActiveListTab, getListPagination, getListSearchParams} from '../selectors';
import {DriversListDepracatedResponse, DriversListResponse} from '../types/api';

let controller = new AbortController();

type TabParams = {withoutTruck: true} | {is_deleted: 1} | {is_deactivated: boolean} | undefined;

function formatSearchParams(params: any): any {
    if (!params) {
        return;
    }

    return {
        ...params,
        owner: isPlainObject(params.owner) ? params.owner.id : params.owner,
    };
}

function getTabParams(tabName: keyof typeof LIST_TABS): TabParams {
    if (tabName === LIST_TABS.DEACTIVATED) {
        return {is_deactivated: true};
    }

    if (tabName === LIST_TABS.WITHOUTTRUCK) {
        return {withoutTruck: true};
    }

    if (tabName === LIST_TABS.ARCHIVED) {
        return {is_deleted: 1};
    }
}

function getDrivers(params: {
    searchParams: any;
    tabParams: TabParams;
    paginationParams: Pagination;
    sortBy: any;
}): AxiosPromise<Driver[]> {
    const {searchParams, tabParams, paginationParams, sortBy} = params;
    const formattedPaginationParams = formatPaginationParams(paginationParams);
    const formattedSearchParams = formatSearchParams(searchParams);
    const mergedParams = merge(formattedSearchParams, tabParams, formattedPaginationParams);
    return searchDrivers({...mergedParams, sortBy});
}

function receiveDrivers(response: DriversListResponse | DriversListDepracatedResponse) {
    if ('pagination' in response.data && 'items' in response.data) {
        return {
            type: types.DRIVERS_RECEIVED,
            payload: {
                drivers: response.data.items,
                pagination: response.data.pagination,
            },
        };
    }

    return {
        type: types.DRIVERS_RECEIVED,
        payload: {
            drivers: response.data,
            pagination: parsePaginationHeaders(response.headers),
        },
    };
}

export function fetchDrivers() {
    return function (dispatch, getState) {
        const state = getState();
        const currentSortFrom = getCurrentSortFrom(state, 'drivers');
        const currentSortBy = getCurrentSortBy(state, 'drivers');
        const searchParams = getListSearchParams(state);
        const paginationParams = getListPagination(state);
        const tabParams = getTabParams(getDriverActiveListTab(state));

        dispatch(appActions.showLoader());
        getDrivers({
            sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : {},
            searchParams,
            tabParams,
            paginationParams,
        })
            .then((response) => dispatch(receiveDrivers(response)))
            .catch((error) => dispatch(appActions.handleError(error)))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function setSearchParams(payload) {
    return {
        type: types.DRIVERS_SET_SEARCH_PARAMS,
        payload,
    };
}

export function setPagination(page, perPage) {
    return function (dispatch) {
        dispatch({
            type: types.DRIVERS_SET_PAGINATION,
            payload: {
                pagination: {
                    currentPage: page,
                    perPage,
                },
            },
        });
    };
}

export function clearState() {
    return {
        type: types.CLEAR_STATE,
    };
}

export function clearListItems() {
    return {
        type: types.DRIVERS_CLEAR_LIST_ITEMS,
    };
}

export function setActiveListTab(tab: keyof typeof LIST_TABS) {
    return {
        type: types.DRIVERS_SET_ACTIVE_LIST_TAB,
        payload: tab,
    };
}

export const getDriverPhoneNumber = async (params: {driverID: string}): Promise<string | undefined> => {
    const {driverID} = params;

    controller.abort();
    controller = new AbortController();

    try {
        const {data} = await revealDriverHiddenData({driverID}, {signal: controller.signal});

        return head(data)?.mobile_phone;
    } catch (error) {
        console.log(error);
    }
};

export const getDriverEmail = async (params: {driverID: string}): Promise<string | undefined> => {
    const {driverID} = params;

    controller.abort();
    controller = new AbortController();

    try {
        const {data} = await revealDriverHiddenData({driverID}, {signal: controller.signal});

        return head(data)?.email;
    } catch (error) {
        console.log(error);
    }
};
