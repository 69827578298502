import {ServerItem} from 'core/entities/Owner/types';

import {TruckCreateUpdateFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import useSelectCompaniesState from './useSelectCompaniesState';

const getName = getTypeFieldNameFactory<TruckCreateUpdateFormValues>();

type Params = {company?: ServerItem; index?: number; fromInitial?: boolean; truckForm: string};

type SyncItem = {label: string; value: number; hasActiveLoad: boolean} | null;

type UseSyncTableRows = {
    companiesForSelectionAll: SyncItem[];
    companiesForClearAll: SyncItem[];
    isDisallowTurnOff: boolean;
    isNotAvailable: boolean;
    isDisabled: boolean;
};

const useSyncTableRows = (params: Params): UseSyncTableRows => {
    const {company, fromInitial, truckForm} = params;

    const selectedOwner = useGetReduxFormValue(truckForm, getName('owner'));
    const {companies, initialCompanies} = useSelectCompaniesState();

    const sourceCompanies = fromInitial ? initialCompanies : companies;

    const ownerCompanies = selectedOwner?.value?.companies || [];
    const ownerCompany = ownerCompanies.find((item) => item.id === company?.id);
    const ownerCompanyHasActiveLoad = Boolean(ownerCompany?.hasActiveLoad);

    const companiesForSelectionAll = (sourceCompanies || []).map((sourceCompany) => {
        const foundCompany = ownerCompanies.find((item) => item?.id === sourceCompany?.id);

        const {company_name, id} = sourceCompany || {};
        const {hasActiveLoad} = foundCompany || {};

        if (foundCompany) {
            return {hasActiveLoad, label: company_name, value: id};
        }

        return null;
    });

    const companiesForClearAll = (sourceCompanies || []).map((sourceCompany) => {
        const foundCompany = ownerCompanies.find((item) => item?.id === sourceCompany?.id);

        const {company_name, id} = sourceCompany || {};
        const {hasActiveLoad} = foundCompany || {};

        if (foundCompany && hasActiveLoad) {
            return {hasActiveLoad, label: company_name, value: id};
        }

        return null;
    });

    return {
        isDisabled: !ownerCompany || ownerCompanyHasActiveLoad,
        isDisallowTurnOff: ownerCompanyHasActiveLoad,
        isNotAvailable: !ownerCompany,
        companiesForSelectionAll,
        companiesForClearAll,
    };
};

export default useSyncTableRows;
