import {toast} from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {AppThunk} from 'store';
import {handleError, hideLoader, showLoader} from 'store/actions';
import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import Driver from 'core/entities/Driver/types';
import Owner from 'core/entities/Owner/types';
import {TRUCK_INACTIVE_STATUSES, TRUCK_STATUS_WAITING_FOR_APPROVAL} from 'core/entities/Truck/constants/truckStatuses';
import Truck from 'core/entities/Truck/types';
import {
    createTruck as createTruckUseCase,
    getTruck as getTruckUseCase,
    updateTruck as updateTruckUseCase,
} from 'core/useCases/Truck';

import {deleteOTDGeneralNote} from 'services/restapi/generalNotes';

import notifications from 'components/ui/notifications';

import {transformTemperatureRangeToRequestBody} from 'pages/Trucks/redux/mappers/temperatureRange';
import {CurrentTruck, UpdateFormTab} from 'pages/Trucks/types';
import {TruckCreateUpdateFormValues} from 'pages/Trucks/types/formTypes';
import {getExpiredDocumentsList} from 'pages/Trucks/utils/getExpiredDocumentsList';

import groupFilesByType from 'utils/files/groupFilesByType';

import * as types from '../actionTypes';
import {transformWorkWithCompaniesToRequestBody} from '../mappers/requests';

import {showSuccessModal} from './modal';

export function receiveTruck(truckData: CurrentTruck): types.CurrentTruckActionTypes {
    return {
        type: types.TRUCK_RECEIVED,
        payload: {truckData},
    };
}

export function fetchTruck(number): AppThunk {
    return function (dispatch) {
        dispatch(showLoader());

        getTruckUseCase({truckNumber: number})
            .then((data) => {
                const {truck, truckFiles, truckNotes} = data;

                const mainContact = isEmpty(truck.mainContact) ? (null as any) : truck.mainContact;

                dispatch(
                    receiveTruck({
                        ...{...truck, mainContact},
                        truck_notes: truckNotes,
                        files: groupFilesByType(truckFiles),
                    }),
                );
            })
            .catch((error) => {
                console.error('Error on get truck data: ', error);
                dispatch(handleError(error));
            })
            .finally(() => dispatch(hideLoader()));
    };
}

export const createTruck = (formValues: TruckCreateUpdateFormValues) => async (dispatch, getState) => {
    const {owner, firstDriver, secondDriver, mainPerson, generalNote} = formValues;
    const state = getState();
    const currentDispatcher = getCurrentDispatcher(state);
    const invalidDocuments = getExpiredDocumentsList(formValues);
    const isChangeToWaitingForApproval =
        invalidDocuments.length && !TRUCK_INACTIVE_STATUSES.includes(formValues.status?.value || '');
    const additional_equipment = formValues.additional_equipment?.map?.(({value}) => value)?.join?.(',') || '';

    const truckData: any = {
        ...omit(formValues, [
            'firstDriver',
            'secondDriver',
            'mainPerson',
            'generalNote',
            'rangeMin',
            'rangeMax',
            'degreesUnit',
            'coordinator',
            'workWithCompanies',
        ]),
        additional_equipment,
        status: isChangeToWaitingForApproval ? TRUCK_STATUS_WAITING_FOR_APPROVAL : formValues.status?.value,
        temperatureRange: transformTemperatureRangeToRequestBody(formValues),
        serverIds: transformWorkWithCompaniesToRequestBody(formValues),
        preferred_loads: formValues.preferred_loads?.value,
        license_country: formValues.license_country?.value,
        license_state: formValues.license_state?.value,
        trailer_type: formValues.trailer_type?.value,
        hired_by: formValues.hired_by?.value,
        year: formValues.year?.value,
        type: formValues.type?.value,
    };

    dispatch(showLoader());

    try {
        const {createdTruck} = await createTruckUseCase({
            truckData,
            teamData: {
                owner: owner?.value as Owner,
                firstDriver: firstDriver?.value as Driver,
                secondDriver: secondDriver?.value,
                mainPerson: mainPerson?.value as any,
            },
            dispatcherInitiator: currentDispatcher,
            generalNote: generalNote as string | undefined,
        });

        dispatch(showSuccessModal({truck: createdTruck as Truck, action: 'created'}));

        if (invalidDocuments.length) {
            toast.error(notifications.truckHasExpiredDocumentsError, {data: {invalidDocuments}});
        }
    } catch (e) {
        dispatch(handleError(e));
    }

    dispatch(hideLoader());
};

export function updateTruck(formValues: TruckCreateUpdateFormValues): AppThunk {
    return function (dispatch, getState) {
        const {
            owner,
            firstDriver,
            secondDriver,
            mainPerson,
            generalNote,
            newFiles: addedFiles,
            fileIDsToBeRemoved: deletedFiles,
        } = formValues;
        const state = getState();
        const currentDispatcher = getCurrentDispatcher(state);

        const customFormFields: Array<keyof TruckCreateUpdateFormValues> = [
            'firstDriver',
            'secondDriver',
            'mainPerson',
            'generalNote',
            'newFiles',
            'fileIDsToBeRemoved',
            'degreesUnit',
            'rangeMin',
            'rangeMax',
            'coordinator',
            'workWithCompanies',
        ];

        const truckData: any = {
            ...omit(formValues, customFormFields),
            temperatureRange: transformTemperatureRangeToRequestBody(formValues),
        };

        const invalidDocuments = getExpiredDocumentsList(formValues);
        const isChangeToWaitingForApproval =
            invalidDocuments.length && !TRUCK_INACTIVE_STATUSES.includes(truckData.status?.value);
        const status = isChangeToWaitingForApproval ? TRUCK_STATUS_WAITING_FOR_APPROVAL : truckData.status?.value;
        const additional_equipment = (formValues.additional_equipment || []).map(({value}) => value).join(',');

        dispatch(showLoader());

        updateTruckUseCase({
            updatedTruck: {
                ...truckData,
                status,
                serverIds: transformWorkWithCompaniesToRequestBody(formValues),
                license_country: formValues.license_country?.value || null,
                license_state: formValues.license_state?.value || null,
                trailer_type: formValues.trailer_type?.value || null,
                preferred_loads: formValues.preferred_loads?.value,
                hired_by: formValues.hired_by?.value,
                year: formValues.year?.value || null,
                type: formValues.type?.value,
                additional_equipment,
            },
            teamData: {
                owner: owner?.value as Owner,
                firstDriver: firstDriver?.value as Driver,
                secondDriver: secondDriver?.value,
                mainPerson: mainPerson?.value as any,
            },
            dispatcherInitiator: currentDispatcher,
            addedFiles,
            deletedFiles: deletedFiles || [],
            generalNote: generalNote as string | undefined,
        })
            .then((updatedData) => {
                const {updatedTruck, isRestored} = updatedData;

                dispatch(showSuccessModal({truck: updatedTruck as Truck, action: isRestored ? 'restored' : 'updated'}));

                if (invalidDocuments.length) {
                    toast.error(notifications.truckHasExpiredDocumentsError, {data: {invalidDocuments}});
                }
            })
            .catch((error) => {
                console.error('Error on update truck: ', error);
                dispatch(handleError(error));
            })
            .finally(() => dispatch(hideLoader()));
    };
}

export function deleteTruckGeneralNote(id): AppThunk {
    return function (dispatch) {
        dispatch(showLoader());
        return deleteOTDGeneralNote(id)
            .then((res) => {
                if (res.data === 'deleted') {
                    dispatch({
                        type: types.TRUCK_NOTE_DELETED,
                        payload: {id},
                    });
                }
            })
            .catch((error) => dispatch(handleError(error)))
            .finally(() => dispatch(hideLoader()));
    };
}

export function clearState(): types.CurrentTruckActionTypes {
    return {
        type: types.TRUCK_STATE_CLEAR,
    };
}

export const truckUpdateSetActiveTab = (tab: UpdateFormTab) => ({
    type: types.TRUCK_UPDATE_SET_ACTIVE_TAB,
    payload: tab,
});

export const truckUpdateResetTab = () => ({
    type: types.TRUCK_UPDATE_RESET_TAB,
});

export const getCompaniesTabItemsByPage = (params: {page: number}) => (dispatch) => {
    const {page: followingPage} = params;

    dispatch({type: types.TRUCK_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED, payload: {followingPage}});
};

export const getCompaniesTabItemsByPerPage = (params: {perPage: number}) => (dispatch) => {
    const {perPage} = params;

    dispatch({type: types.TRUCK_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED, payload: {perPage}});
};

export const getCompaniesTabItemsOrderBy = () => (dispatch) => {
    dispatch({type: types.TRUCK_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED});
};
