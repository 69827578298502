import type {AdminSettingsActionsTypes} from './actionCreators';
import * as actionTypes from './actionTypes';

export type AdminSettingsState = {
    dispatchers: {
        showDeleted: boolean;
    };
    carriers: {
        showDeleted: boolean;
    };
    brokers: {
        showDeleted: boolean;
    };
    customers: {
        showDeleted: boolean;
    };
    facilities: {
        showDeleted: boolean;
    };
};

const defaultState = {
    dispatchers: {
        showDeleted: false,
    },
    carriers: {
        showDeleted: false,
    },
    brokers: {
        showDeleted: false,
    },
    customers: {
        showDeleted: false,
    },
    facilities: {
        showDeleted: false,
    },
};

const adminSettings = (
    state: AdminSettingsState = defaultState,
    action: AdminSettingsActionsTypes,
): AdminSettingsState => {
    switch (action.type) {
        case actionTypes.SET_ADMIN_SETTINGS:
            return {
                ...state,
                ...action.payload,
            };

        // no default
    }
    return state;
};

export default adminSettings;
