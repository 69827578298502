import * as websocketsTypes from 'store/middlewares/mainAPISocketsIO/entities/drivers/actionTypes';

import Owner from 'core/entities/Owner/types';
import {mergeTruckLocationWebsocketUpdate} from 'core/entities/Truck';

import * as ownerTypes from 'pages/Owners/redux/actionTypes';

import * as types from '../actionTypes';
import {CurrentDriver, ViewTab} from '../types';

interface CurrentDriverReducerState {
    current: CurrentDriver | null;
    activeTab: ViewTab;
    ownerFieldsForDriver: any;
    initialData: {
        workingWith: Owner | undefined;
        ownerFields: Owner | undefined;
    };
}

const defaultState: CurrentDriverReducerState = {
    current: null,
    activeTab: 'general',
    ownerFieldsForDriver: null,
    initialData: {
        workingWith: undefined,
        ownerFields: undefined,
    },
};

const currentDriverReducer = (state: CurrentDriverReducerState = defaultState, action) => {
    switch (action.type) {
        case types.DRIVER_RECEIVED: {
            return {
                ...state,
                current: action.payload,
            };
        }

        case types.DRIVER_RESTORE_ACCOUNT: {
            return {
                ...state,
                current: {...state.current, disabled: false},
            };
        }

        case types.DRIVER_ARCHIVED: {
            const {driver: archivedDriver} = action.payload;
            if (!state.current) {
                return state;
            }
            return {
                ...state,
                current: {
                    ...state.current,
                    truck: null,
                    truck_id: null,
                    disabled: archivedDriver.disabled,
                    is_deleted: archivedDriver.is_deleted,
                },
            };
        }
        case ownerTypes.SET_OWNER_FIELDS_FOR_DRIVER: {
            return {
                ...state,
                ownerFieldsForDriver: action.payload.fields,
            };
        }

        case types.DRIVER_GENERAL_NOTE_RECEIVED: {
            if (!state.current) {
                return state;
            }

            return {
                ...state,
                current: {
                    ...state.current,
                    generalNotes: [action.payload, ...state.current.generalNotes],
                },
            };
        }

        case types.DRIVER_GENERAL_NOTE_DELETED: {
            if (!state.current) {
                return state;
            }

            return {
                ...state,
                current: {
                    ...state.current,
                    generalNotes: state.current.generalNotes.filter((item) => item.id !== action.payload.id),
                },
            };
        }

        case websocketsTypes.WEB_SOCKET_DRIVERS_TRUCKS_UPDATE_RECEIVED: {
            if (!state.current) {
                return state;
            }
            const updatedTruck = action.payload;

            return {
                ...state,
                current: {
                    ...state.current,
                    truck:
                        state.current.truck && state.current.truck.id === updatedTruck.id
                            ? {...state.current.truck, ...updatedTruck}
                            : state.current.truck,
                },
            };
        }

        case websocketsTypes.WEB_SOCKET_DRIVERS_TRUCKS_COORDINATES_RECEIVED: {
            if (!state.current || !state.current.truck) {
                return state;
            }
            const updatedLocation = action.payload;

            return {
                ...state,
                current: {
                    ...state.current,
                    truck:
                        state.current.truck.id === updatedLocation.truck
                            ? mergeTruckLocationWebsocketUpdate(state.current.truck, updatedLocation)
                            : state.current.truck,
                },
            };
        }

        case types.FORM_TAB_CHANGED: {
            return {
                ...state,
                activeTab: action.payload.activeTab,
            };
        }

        case types.SET_DRIVER_WORKING_WITH_OWNER: {
            const transferredOwner = action.payload;
            return {
                ...state,
                initialData: {
                    ...state.initialData,
                    workingWith: transferredOwner,
                },
            };
        }

        case types.SET_DRIVER_FIELDS_COPIED_FROM_OWNER: {
            const transferredOwner = action.payload;
            return {
                ...state,
                initialData: {
                    ...state.initialData,
                    ownerFields: transferredOwner,
                },
            };
        }

        case types.CLEAR_DRIVER_STATE: {
            return defaultState;
        }

        default:
            return state;
    }
};

export default currentDriverReducer;
