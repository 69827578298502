import Carrier from 'core/entities/Carrier/types';
import Truck from 'core/entities/Truck/types';

import * as types from 'pages/TrucksNearby/redux/actionTypes/workWithCompanies';
import * as mappers from 'pages/TrucksNearby/redux/mappers';

export type State = {
    orderBy: 'asc' | 'desc' | null;
    companies: Carrier[];
    truck: Truck | null;
};

const defaultState: State = {
    companies: [],
    orderBy: null,
    truck: null,
};

const workWithCompaniesReducer = (state = defaultState, action): State => {
    switch (action.type) {
        case types.WORK_WITH_COMPANIES_INITIALIZED: {
            const {truck, companies} = action.payload;

            return {...state, companies, truck};
        }

        case types.WORK_WITH_COMPANIES_ORDER_BY_RECEIVED: {
            const {companies} = action.payload;

            return mappers.handleReducerByWorkWithCompaniesByOrderByReceived({state, companies});
        }

        case types.WORK_WITH_COMPANIES_STATE_CLEARED: {
            return {...defaultState};
        }

        default:
            return state;
    }
};

export default workWithCompaniesReducer;
