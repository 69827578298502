import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';

import {getPastTimeFrom} from 'utils/dateTime';

export const isValidLength = (from, to, value) => from <= value.length && to >= value.length;

export const isValidPersonAge = (date) => {
    const minPersonAge = 18;
    const maxPersonAge = 90;
    const {years} = getPastTimeFrom(date);
    const age = Number(Math.abs(years).toFixed(0));
    return age >= minPersonAge && age <= maxPersonAge;
};

export const hasValueChanged = (values, initialValues, key) => {
    return values[key] && values[key] !== (initialValues && initialValues[key]);
};

export const validateBlurredField = ({blurredField, values, fieldsRequestMap, initialValues}) => {
    // when we press enter, blurredField is undefined, so we get fieldName manually
    const activeInput = document.activeElement as HTMLInputElement;
    const activeInputName = activeInput && activeInput.name;
    const blurredFieldName = blurredField || activeInputName;

    const makeValidationRequest = fieldsRequestMap[blurredFieldName];
    if (makeValidationRequest) {
        return makeValidationRequest(values, blurredFieldName, initialValues);
    }
    return null;
};

export const makeAsyncValidate = ({fieldsRequestMap}) => async (values, dispatch, props, blurredField) => {
    const {asyncErrors, initialValues} = props;

    const blurredFieldError = await validateBlurredField({blurredField, values, fieldsRequestMap, initialValues});
    const mergedErrors = {...asyncErrors, ...blurredFieldError};

    return new Promise<void>((resolve, reject) => {
        if (!isEmpty(mergedErrors)) {
            reject(mergedErrors);
        } else {
            resolve();
        }
    });
};

export const validateNumericValue = (value: any): string[] | void => {
    const error = ['is not a number'];

    if (isArray(value) || isObject(value) || isFunction(value) || isNaN(value) || isBoolean(value)) {
        return error;
    }

    if (isString(value)) {
        if (value.length === 0) {
            return error;
        }

        if (isNaN(toNumber(value))) {
            return error;
        }
    }
};

export const checkIsValidZip = ({
    zipValue = '',
    zipFieldName = 'zip',
    countryValue = '',
    errorMessage = `ZIP code wasn't found, please try another.`,
} = {}) => undefined;
// getGeoCodeData([
//     {
//         params: {
//             componentRestrictions: {
//                 postalCode: zipValue,
//                 country: countryValue,
//             },
//         },
//     },
// ]).then(res => {
//     const {address, lat, lng} = res[0];
//
//     if (!lat || !lng || !address.hasZip) {
//         return {[zipFieldName]: errorMessage};
//     }
// });
