import requests, {getTruckNotes} from 'core/gateways/TruckApiGateway/requests';

import Truck from 'core/entities/Truck/types';
import {Note, DispatcherNoteApi} from 'core/entities/Truck/types/Note';

export const fetchGeneralTruckNotes = async (truckData: Truck): Promise<Note[]> => {
    const truckNotes = await getTruckNotes(truckData.id);
    return truckNotes;
};

export const postGeneralNote = async (note: string, truckID: string): Promise<Note> => {
    const newNote = await requests.postTruckNote(truckID, note).then((postedNote) => postedNote);
    return newNote;
};

export const postDispatcherNote = async (dispatcher_note: string, truckID: string): Promise<DispatcherNoteApi> => {
    const newNote = await requests.updateDispatcherNote(truckID, {dispatcher_note}).then((response) => response.data);
    return newNote;
};
