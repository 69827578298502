import {postFiles} from 'services/restapi';
import isEmpty from 'lodash/isEmpty';

import StoredFile from 'types/File';
import {UploadedFile, GroupedUploadedFiles} from 'components/ui/Files/FileUpload/types';
import formatFileExtraData from 'utils/files/formatFileExtraData';

function mapNativeFileObjects(files: UploadedFile[]): File[] {
    return files.map((file) => file.file);
}

function mapFilesExtraData(files: UploadedFile[]): {[key: string]: string}[] {
    return files.map((item) => formatFileExtraData(item));
}

function postGroupedFiles(
    id: string,
    entity: string,
    groupedFiles: GroupedUploadedFiles,
): Promise<StoredFile[] | void> {
    if (isEmpty(groupedFiles)) {
        return Promise.resolve();
    }
    const promises = Object.entries(groupedFiles).map(([type, files]) => {
        if (isEmpty(files)) {
            return null;
        }
        const nativeFileObjects = mapNativeFileObjects(files);
        const extraData = mapFilesExtraData(files);
        const params = {id, entity, type, extraData};

        return postFiles(nativeFileObjects, params).then((res: any) => {
            if ('data' in res) {
                return res.data;
            }
        });
    });

    return Promise.all(promises);
}

export default postGroupedFiles;
