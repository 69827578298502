import React from 'react';
import {Modal} from 'react-bootstrap';

import Form from './components/Form';

type OwnProps = {
    data: {
        entity: {
            email?: string;
        };
    };
    onClose?();
    handlers: any;
};

export const PasswordModal: React.FC<OwnProps> = (props) => {
    const {
        onClose,
        handlers,
        data: {entity},
    } = props;

    const initValues = {
        email: entity.email,
    };

    const onSubmit = (fields) => {
        handlers.handleSubmit({fields});
        onClose?.();
    };

    return (
        <Modal show={true} onHide={onClose} dialogClassName="custom-modal standard-form-modal" size="sm">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Change driver&apos;s password</Modal.Title>

                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form
                        form="change-driver-password"
                        onSubmit={onSubmit}
                        onClose={onClose}
                        initialValues={initValues}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PasswordModal;
