import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import isNumber from 'lodash/isNumber';

import {isStopPlanned} from 'core/entities/Load/modules/loadStop';
import {PERMISSIONS} from 'core/entities/Permissions';
import PlannedTravelOrder from 'core/entities/TravelOrder/types/PlannedTravelOrder';
import {fetchPlannedTravelOrders} from 'core/gateways/TruckApiGateway/requests';

import RefLink from 'components/common/Load/RefNumberLink';
import TimeFrameView from 'components/common/Load/TimeFrameView';
import Button from 'components/ui/Buttons/Button';
import {LoadLink, TravelOrderLink} from 'components/ui/Links';
import Tooltip from 'components/ui/Tooltip';

import {transformDistance} from 'utils/distance';
import {getCityLineFromObject} from 'utils/getCityLine';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';

import styles from './plannedTravelOrders.module.scss';

const PlannedTravelOrders = ({data: {truck}, onClose}) => {
    const location = useLocation();
    const [plannedTravelOrders, setPlannedTravelOrders] = useState<PlannedTravelOrder[] | null>(null);

    const permissionViewTravelOrder = useCheckIfUserHasPermission(PERMISSIONS.TRAVEL_ORDER_VIEW);

    useEffect(() => {
        async function getPlannedTravelOrders() {
            const plannedTO = await fetchPlannedTravelOrders(truck.id);
            setPlannedTravelOrders(plannedTO);
        }
        getPlannedTravelOrders();
    }, []);

    useEffect(() => {
        return onClose;
    }, [location]);

    if (!plannedTravelOrders) {
        return null;
    }

    return (
        <div className={`standard-form-modal modal-content ${styles.modal}`}>
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        Planned Loads of the <strong>Truck#{truck.number}</strong>
                    </Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="standard-table-style custom-scroll">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>LOAD AND TO#</th>
                                <th>TO STEP</th>
                                <th>FIRST PICK UP</th>
                                <th>LAST DELIVERY</th>
                                <th>MILES</th>
                            </tr>
                        </thead>
                        <tbody className="table-body-bordered">
                            {plannedTravelOrders.map((travelOrder, index) => {
                                const rowNumber = index + 1;
                                const {
                                    load,
                                    status,
                                    number,
                                    isForeign,
                                    emptyMiles,
                                    loadedMiles,
                                    firstPickUp,
                                    lastDelivery,
                                    bookedByDispatcherFullName,
                                } = travelOrder;
                                const showEmptyMiles = isNumber(emptyMiles);
                                const isPlannedFirstStop = isStopPlanned(firstPickUp);
                                const isPlannedLastStop = isStopPlanned(lastDelivery);
                                const firstPickUpAddress = getCityLineFromObject(firstPickUp.facility || undefined);
                                const lastDeliveryAddress = getCityLineFromObject(lastDelivery.facility || undefined);
                                const loadLinkTooltipJSX = (
                                    <span>
                                        <span>load#{load?.number}</span>
                                        {bookedByDispatcherFullName ? (
                                            <>
                                                <br />
                                                <span>
                                                    booked by: <strong>{bookedByDispatcherFullName}</strong>
                                                </span>
                                            </>
                                        ) : null}
                                        <br />
                                        <span>
                                            <em>click to view the load</em>
                                        </span>
                                        <br />
                                    </span>
                                );

                                return (
                                    <tr key={number || firstPickUpAddress + lastDeliveryAddress}>
                                        <td>{rowNumber}.</td>
                                        <td>
                                            {isForeign ? (
                                                'Partners load'
                                            ) : (
                                                <Tooltip tooltipContent={loadLinkTooltipJSX}>
                                                    <strong>
                                                        <LoadLink
                                                            load={load}
                                                            content={`Load#${load.number}`}
                                                            className="second-link"
                                                        />
                                                    </strong>
                                                </Tooltip>
                                            )}
                                            <br />
                                            {isForeign ? (
                                                'Partners TO'
                                            ) : (
                                                <TravelOrderLink
                                                    travelOrder={travelOrder}
                                                    content={`T.O#${number}`}
                                                    className="second-link"
                                                    disabled={!permissionViewTravelOrder}
                                                    isBold={true}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {isForeign ? (
                                                'No data'
                                            ) : (
                                                <>
                                                    <b>{status}</b>
                                                    <br />
                                                    <RefLink load={load} />
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {firstPickUpAddress} <br />
                                            <TimeFrameView
                                                loadStop={firstPickUp}
                                                isHighlightDelay={isPlannedFirstStop}
                                                inLine={false}
                                            />
                                        </td>
                                        <td>
                                            {lastDeliveryAddress}
                                            <br />
                                            <TimeFrameView
                                                loadStop={lastDelivery}
                                                isHighlightDelay={isPlannedLastStop}
                                                inLine={false}
                                            />
                                        </td>
                                        <td>
                                            loaded:
                                            <b> {transformDistance(loadedMiles || 0).distanceWithShortUnit}</b>
                                            <br />
                                            {showEmptyMiles ? (
                                                <>
                                                    empty:
                                                    <b> {transformDistance(emptyMiles || 0).distanceWithShortUnit} </b>
                                                </>
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="modal-body__bottom">
                    <div>
                        <Button onClick={onClose}>Close</Button>
                    </div>
                </div>
            </Modal.Body>
        </div>
    );
};

export default PlannedTravelOrders;
