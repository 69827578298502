import {toast} from 'react-toastify';
import {change} from 'redux-form';

import * as appActions from 'store/actions';

import Driver from 'core/entities/Driver/types';
import {
    createDriver as createDriverApiReq,
    getDriverById,
    getDriverGeneralNotes,
    getGroupedDriverFiles,
    postDriverGeneralNote,
    restoreDriver,
    restoreDriverAccount,
    searchDriverDuplicates,
    updateDriver as updateDriverApiReq,
    updateDriverPassword,
} from 'core/gateways/DriverApiGateway/requests';
import {getTruckByNumber} from 'core/gateways/TruckApiGateway/requests';

import {deleteFiles} from 'services/restapi';
import {deleteOTDGeneralNote} from 'services/restapi/generalNotes';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';
import notifications from 'components/ui/notifications';

import {ENTITY_NAME_DRIVERS} from 'utils/data/entityNames';
import postGroupedFiles from 'utils/files/postGroupedFiles';
import {getCityLineFromObject} from 'utils/getCityLine';

import * as types from '../actionTypes';
import {modalNames} from '../components/modals/modalMap';
import {FIELDS} from '../constants/fields';
import {getCurrentDriver, getDriverIsRestoreAccount} from '../selectors';

import {checkIfUniqueFieldsChanged, formatDriverFormFields} from './helpers';
import {
    showCreateDriverWithoutOwnerModal,
    showDuplicateModal,
    showRestoreDriverWithoutOwnerModal,
    showSuccessModal,
} from './modal';

async function getDriverWithNestedDataById(driverID: string): Promise<Driver | void> {
    const driver = await getDriverById(driverID).then((response) => response.data);
    if (!driver) {
        return Promise.resolve();
    }
    // Driver's truck is fetched separately because nested object may miss some of the needed fields
    const {truck: driverTruck} = driver;
    if (driverTruck && driverTruck.number) {
        const truck = await getTruckByNumber(driverTruck.number).then(({data}) => data);

        return {...driver, truck: truck || driverTruck};
    }

    return driver;
}

function receiveDriver(payload) {
    return {
        type: types.DRIVER_RECEIVED,
        payload,
    };
}

function restoreAccount() {
    return {
        type: types.DRIVER_RESTORE_ACCOUNT,
    };
}

export function fetchDriver(driverID) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());
        const [driver, generalNotes, files] = await Promise.all([
            getDriverWithNestedDataById(driverID).catch((error) => dispatch(appActions.handleError(error))),
            getDriverGeneralNotes(driverID).catch((error) => console.error(error)),
            getGroupedDriverFiles(driverID).catch((error) => console.error(error)),
        ]);
        dispatch(appActions.hideLoader());
        if (!driver) {
            return;
        }
        dispatch(receiveDriver({...driver, files, generalNotes}));
    };
}

async function updateFiles(formFields): Promise<any> {
    const {id: driverId, new_files, fileIDsToBeRemoved} = formFields;
    await deleteFiles(fileIDsToBeRemoved);
    return postGroupedFiles(driverId, ENTITY_NAME_DRIVERS, new_files);
}

type RestoreDriverAccountParams = {
    driverID: string;
    isDontShowSuccessModal?: boolean;
};
export const restoreDriverAccountSubmit = (params: RestoreDriverAccountParams) => {
    const {driverID, isDontShowSuccessModal} = params;

    return async (dispatch) => {
        try {
            dispatch(appActions.showLoader());
            await restoreDriverAccount(driverID);
            dispatch(modalActions.closeAll());
            if (!isDontShowSuccessModal) {
                dispatch(
                    modalActions.openModal({
                        modalName: commonModalNames.informationModal,
                        data: {
                            title: 'Successful Restore Account',
                            message: 'Driver’s Mobile Application Account has been restored successfully.',
                            rightButtonTitle: 'ok',
                            bodyType: 'Success',
                            buttonType: 'success',
                        },
                    }),
                );
            }
            dispatch(restoreAccount());
        } catch (error) {
            dispatch(appActions.handleError(error));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
};

export function createDriver(formFields): any {
    return async function (dispatch, getState) {
        const state = getState();

        const formattedDriver = formatDriverFormFields(formFields);
        const {newGeneralNote} = formFields;

        dispatch(appActions.showLoader());

        const driverDuplicate = await searchDriverDuplicates(formattedDriver);

        if (driverDuplicate) {
            dispatch(showDuplicateModal(driverDuplicate));
            dispatch(appActions.hideLoader());
            return;
        }

        if (!formFields.owner) {
            dispatch(showCreateDriverWithoutOwnerModal());
            dispatch(appActions.hideLoader());
            return;
        }

        const createdDriver = await createDriverApiReq(formattedDriver)
            .then(({data}) => data)
            .catch((error) => dispatch(appActions.handleError(error)))
            .finally(() => dispatch(appActions.hideLoader()));

        if (!createdDriver) {
            return;
        }

        if (newGeneralNote) {
            await postDriverGeneralNote(createdDriver.id, newGeneralNote);
        }

        dispatch(showSuccessModal({driver: createdDriver, action: 'created'}));
    };
}

export function updateDriver(formFields) {
    return async function (dispatch, getState) {
        dispatch(appActions.showLoader());

        const state = getState();

        let formattedDriver = formatDriverFormFields(formFields);
        let isRestored = false;
        const currentDriver = getCurrentDriver(state);
        const isRestoreAccount = getDriverIsRestoreAccount(state);
        const {newGeneralNote} = formFields;
        const isDuplicateFieldsChanged = checkIfUniqueFieldsChanged(formattedDriver, currentDriver);

        if (isDuplicateFieldsChanged) {
            const driverDuplicate = await searchDriverDuplicates(formattedDriver);
            if (driverDuplicate) {
                dispatch(showDuplicateModal(driverDuplicate));
                dispatch(appActions.hideLoader());
                return;
            }
        }

        if (formattedDriver.is_deleted) {
            if (!formattedDriver.owner) {
                dispatch(showRestoreDriverWithoutOwnerModal());
                dispatch(appActions.hideLoader());
                return;
            }

            let isRestoreFailed = false;
            const restoredDriver = await restoreDriver(formattedDriver)
                .then((res) => res.data)
                .catch((error) => {
                    let errorMessage = 'Oops something unexpected happened. Try again later';

                    if (error?.data?.data?.error?.includes?.('User account already exists')) {
                        errorMessage = 'A driver with this email exists, please specify another email';
                    }

                    dispatch(
                        modalActions.openModal({
                            modalName: commonModalNames.informationModal,
                            data: {
                                title: error.statusText,
                                rightButtonTitle: 'ok',
                                errorMessage,
                                bodyType: 'ErrorForm',
                                buttonType: 'danger',
                            },
                        }),
                    );
                    isRestoreFailed = true;
                });

            if (isRestoreFailed) {
                dispatch(appActions.hideLoader());
                return;
            }

            formattedDriver = formatDriverFormFields(restoredDriver);
            isRestored = true;
        }

        if (isRestored && isRestoreAccount) {
            await dispatch(restoreDriverAccountSubmit({driverID: currentDriver.id, isDontShowSuccessModal: true}));
        }

        const updatedDriver = await updateDriverApiReq(formattedDriver)
            .then(({data}) => data)
            .catch((error) => dispatch(appActions.handleError(error)))
            .finally(() => dispatch(appActions.hideLoader()));

        if (!updatedDriver) {
            return;
        }

        if (newGeneralNote) {
            await postDriverGeneralNote(updatedDriver.id, newGeneralNote);
        }

        try {
            await updateFiles(formFields);

            toast.success(notifications.filesSuccessfulAdded, {data: {}});
        } catch (e) {
            toast.error(notifications.filesUnsuccessfulAdded, {data: {}});
        }

        dispatch(appActions.hideLoader());
        dispatch(
            showSuccessModal({
                action: isRestored ? 'restored' : 'updated',
                driver: updatedDriver,
                isAccountRestored: isRestored && isRestoreAccount,
            }),
        );
    };
}

function receiveGeneralNote(payload) {
    return {
        type: types.DRIVER_GENERAL_NOTE_RECEIVED,
        payload,
    };
}

export function addNote(note) {
    return function (dispatch, getState) {
        const {current: currentDriver} = getState().drivers.current;
        dispatch(appActions.showLoader());
        postDriverGeneralNote(currentDriver.id, note.text)
            .then((data) => dispatch(receiveGeneralNote(data)))
            .catch((error) => dispatch(appActions.handleError(error)))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function deleteDriverGeneralNote(id) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        deleteOTDGeneralNote(id)
            .then((response) => {
                if (response.data === 'deleted') {
                    dispatch({
                        type: types.DRIVER_GENERAL_NOTE_DELETED,
                        payload: {id},
                    });
                }
            })
            .catch((error) => dispatch(appActions.handleError(error)))
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function setOwnerFieldsToDriverForm(formName: string): any {
    return function (dispatch, getState) {
        const ownerFields = getState().drivers.current.ownerFieldsForDriver;
        if (!ownerFields) {
            return;
        }
        const mapOwnerFields = {
            email: ownerFields.email,
            is_owner: ownerFields.is_owner,
            language: ownerFields.language,
            full_name: ownerFields.full_name,
            date_of_birthday: ownerFields.date_of_birthday,
            address_line: ownerFields.address_line,
            additional_address_line: ownerFields.additional_address_line,
            citizenship: ownerFields.citizenship,
            city: ownerFields.city,
            state: ownerFields.state,
            zip: ownerFields.zip,
            country: ownerFields.country,
            [FIELDS.custom.driverHomeLocation]: getCityLineFromObject({
                city: ownerFields.city,
                state: ownerFields.state,
                zip: ownerFields.zip,
                country: ownerFields.country,
            }),
            [FIELDS.contact.home_lat]: ownerFields.home_lat,
            [FIELDS.contact.home_lng]: ownerFields.home_lng,
            home_phone: ownerFields.home_phone,
            mobile_phone: ownerFields.mobile_phone,
            additional_mobile_phone: ownerFields.additional_mobile_phone,
            ec_name: ownerFields.ec_name,
            relationship: ownerFields.relationship,
            ec_phone: ownerFields.ec_phone,
            ec_phone2: ownerFields.ec_phone2,
            owner: {
                full_name: ownerFields.full_name,
                home_lat: ownerFields.home_lat,
                home_lng: ownerFields.home_lng,
                id: ownerFields?.id || ownerFields?.owner?.id,
                language: ownerFields.language,
            },
        };

        Object.keys(mapOwnerFields).forEach((item) => dispatch(change(formName, item, mapOwnerFields[item])));
    };
}

export function passwordSubmit(driverID, fields) {
    return function (dispatch) {
        const {password, ...passwordData} = fields;
        const errorMessage = 'password change error';

        dispatch(appActions.showLoader());

        return updateDriverPassword(driverID, {...passwordData, new_password: password})
            .then((response) => {
                dispatch(modalActions.openModal({modalName: modalNames.successfulChangePasswordModal}));
                if (!response.data) {
                    dispatch(appActions.handleError(errorMessage));
                }
            })
            .catch((error) => {
                dispatch(appActions.handleError(errorMessage));
                console.error(error);
            })
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function clearState() {
    return {
        type: types.CLEAR_DRIVER_STATE,
    };
}

export function changeTab(tab) {
    return {
        type: types.FORM_TAB_CHANGED,
        payload: {
            activeTab: tab,
        },
    };
}

export function setIsRestoreDriverAccount(isRestoreAccount: boolean) {
    return {
        type: types.SET_IS_RESTORE_DRIVER_ACCOUNT,
        payload: isRestoreAccount,
    };
}
