import {useSelector} from 'react-redux';

import * as selectors from 'pages/TrucksNearby/redux/selectors/workWithCompanies';

type UseTruckWorkWithCompaniesState = {
    orderBy: ReturnType<typeof selectors.getTruckCompaniesOrderBy>;
    companies: ReturnType<typeof selectors.getTruckCompanies>;
    truck: ReturnType<typeof selectors.getTruck>;
};

const useTruckWorkWithCompaniesState = (): UseTruckWorkWithCompaniesState => ({
    orderBy: useSelector(selectors.getTruckCompaniesOrderBy),
    companies: useSelector(selectors.getTruckCompanies),
    truck: useSelector(selectors.getTruck),
});

export default useTruckWorkWithCompaniesState;
