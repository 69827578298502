import {ReeferTruckTemperatureRange} from 'core/entities/Truck/types';
import {isReeferTruckType} from 'core/useCases/Truck/utils';

import {TruckCreateUpdateFormValues} from 'pages/Trucks/types/formTypes';

export const transformTemperatureRangeToRequestBody = (
    formValues: TruckCreateUpdateFormValues,
): ReeferTruckTemperatureRange | null => {
    const {type, degreesUnit, rangeMin, rangeMax} = formValues;

    if (!isReeferTruckType(type?.value) || !rangeMin || !rangeMax || !degreesUnit) {
        return null;
    }

    return {
        degreesUnit,
        rangeMin: Number(rangeMin),
        rangeMax: Number(rangeMax),
    };
};
