import {useDispatch} from 'react-redux';

import * as selectCompaniesActions from 'pages/Trucks/redux/actions/selectCompanies';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

type UseSelectCompaniesActions = {
    resetCompaniesState: typeof selectCompaniesActions.selectCompaniesActionCreators.resetCompaniesState;
    setIsInitialCompaniesLoaded: typeof selectCompaniesActions.selectCompaniesActionCreators.setIsInitialCompaniesLoaded;
    saveSelectedCompanies: (params: {formValues: Partial<SelectCompaniesModalFormValues>; formName: string}) => void;
    getSimilarCompaniesDropdown: (params: {formValues: Partial<SelectCompaniesModalFormValues>}) => any;
    getInitialCompaniesList: (params?: {onPostSuccessFunc?: (companies) => void}) => void;
    clearSelectCompaniesModalState: () => void;
    getCompaniesBySorting: () => void;
    getCompaniesByFilters: (params: {
        filters: Partial<SelectCompaniesModalFormValues>;
        onPostSuccessFunc?: () => void;
    }) => void;
};

const useSelectCompaniesActions = (): UseSelectCompaniesActions => {
    const dispatch = useDispatch();

    return {
        setIsInitialCompaniesLoaded: (isLoaded) =>
            dispatch(selectCompaniesActions.selectCompaniesActionCreators.setIsInitialCompaniesLoaded(isLoaded)),
        resetCompaniesState: () => dispatch(selectCompaniesActions.selectCompaniesActionCreators.resetCompaniesState()),
        getSimilarCompaniesDropdown: (params) => dispatch(selectCompaniesActions.getSimilarCompaniesDropdown(params)),
        getInitialCompaniesList: (params) => dispatch(selectCompaniesActions.getInitialSelectCompaniesList(params)),
        clearSelectCompaniesModalState: () => dispatch(selectCompaniesActions.clearSelectCompaniesModalState()),
        saveSelectedCompanies: (params) => dispatch(selectCompaniesActions.saveSelectedCompanies(params)),
        getCompaniesByFilters: (params) => dispatch(selectCompaniesActions.getCompaniesByFilters(params)),
        getCompaniesBySorting: () => dispatch(selectCompaniesActions.getCompaniesBySorting()),
    };
};

export default useSelectCompaniesActions;
