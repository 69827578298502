import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import {change, Field, formValueSelector, reduxForm} from 'redux-form';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import {PermissionsValues} from 'core/entities/Permissions';

import appConfig from 'config';

import Button from 'components/ui/Buttons/Button';
import {DispatcherLink} from 'components/ui/Links';

import {createDate} from 'utils/dateTime';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';
import useCurrentDispatcher from 'hooks/useCurrentDispatcher';
import useDomainSettings from 'hooks/useDomainSettings';

import GeneralNote from 'types/GeneralNote';

import styles from './notes.module.scss';

const ADD_TO_NOTES_FORM_NAME = 'add-notes-modal-form';

type OwnProps = {
    data: {
        title: string;
        entity: any;
        addNoteDisabled: boolean;
        deleteNote: (noteID: string) => void;
        deletePermissionName: PermissionsValues;
    };
    handlers: {onSubmit()};
    handleSubmit(values: any);
    notes: GeneralNote[];
    noteField?: string;
    onClose();
    selectors: {
        [funcName: string]: Function;
    };
    values: {text: string};
    form: string;
};

const Form: React.FC<OwnProps> = (props) => {
    const {
        data: {title, entity, addNoteDisabled, deleteNote, deletePermissionName},
        handlers: {onSubmit},
        handleSubmit,
        notes = [],
        noteField,
        onClose,
    } = props;

    const {currentDispatcher} = useCurrentDispatcher();
    const {isDispatchlandRecruitingAgency} = useDomainSettings();
    const permissionDeleteNotes = useCheckIfUserHasPermission(deletePermissionName);
    const isCurrentEntityArchived = entity?.is_deleted;

    const sortByDateTime = (items) => orderBy(items, (noteA) => noteA.post_date, ['desc']);

    return (
        <div className={`${styles.modal} modal-content`}>
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>{title}</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className="modal-body__notes">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-body__main">
                            <div className="modal-body__nodes-list">
                                {sortByDateTime(notes).map((note, index) => {
                                    const {text, post_date, is_important, dispatcher} = note || {};

                                    if (!text) {
                                        return null;
                                    }

                                    const isUserFromDifferentServer =
                                        (!isDispatchlandRecruitingAgency &&
                                            dispatcher?.company_name !== currentDispatcher?.company?.name) ||
                                        (isDispatchlandRecruitingAgency &&
                                            dispatcher?.prefix !== appConfig.REACT_APP_PREFIX);
                                    const isDeleteBtnHidden =
                                        !permissionDeleteNotes || !deleteNote || isUserFromDifferentServer;
                                    const dispatcherLink = dispatcher ? (
                                        <DispatcherLink
                                            className="link"
                                            dispatcher={dispatcher}
                                            disabled={isUserFromDifferentServer}
                                            content={getDispatcherName(dispatcher)}
                                        />
                                    ) : null;

                                    const timeOfCreate = (post_date && createDate(post_date).fullDate) || null;
                                    const isImportant = is_important;

                                    return (
                                        <div
                                            key={index}
                                            className={classNames('notes-item', {
                                                'notes-item__important': isImportant,
                                            })}
                                        >
                                            <div className="header">
                                                <span className="font-medium">GENERAL NOTE</span>
                                                <div className="postDate">
                                                    <span className="ws-nowrap">{timeOfCreate}</span>
                                                    {!isDeleteBtnHidden ? (
                                                        <Button
                                                            onClick={() => {
                                                                deleteNote(note.id);
                                                            }}
                                                            disabled={!permissionDeleteNotes}
                                                            buttonIcon="trash"
                                                            buttonSize="icon"
                                                            className="btn-link deleteBtn"
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="linkWrapper">
                                                <h4 className="dispatcherName">{dispatcherLink}</h4>
                                                {dispatcher.company_name ? (
                                                    <span className="companyName font-medium">
                                                        <span className="ml10 mr10">|</span>
                                                        {dispatcher.company_name}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <p>{text}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="add-note-field">
                                <label className="form-label">
                                    <strong>Add Note:</strong>
                                </label>
                                <Field
                                    disabled={isCurrentEntityArchived || addNoteDisabled}
                                    name="text"
                                    component="textarea"
                                    placeholder="type a note here"
                                    className="form-control"
                                    rows="2"
                                />
                            </div>
                        </div>

                        <div className="modal-body__bottom">
                            <Button onClick={onClose}>Cancel</Button>
                            <Button colorTheme="blue" type="submit" disabled={!noteField || addNoteDisabled}>
                                add
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </div>
    );
};

const selector = formValueSelector(ADD_TO_NOTES_FORM_NAME);

export default connect<{}, {}, OwnProps>((state, props) => ({
    notes: props.selectors.notesSelector(state),
    noteField: selector(state, 'text'),
}))(
    reduxForm<{}, OwnProps>({
        form: ADD_TO_NOTES_FORM_NAME,
        onSubmitSuccess: (result, dispatch, props) => {
            dispatch(change(props.form, 'text', ''));
        },
        validate: (values: {text: string}) => {
            const errors: {text?: string} = {};
            if (!values.text) {
                errors.text = 'Required';
            }
            return errors;
        },
    })(Form),
);
