import {AxiosResponse} from 'axios';
import intersection from 'lodash/intersection';
import keys from 'lodash/keys';

import {
    cancelCrossServerTruckReservation,
    commonTruckReserveUpdated,
    completeCrossServerTruckReservation,
} from 'store/actionCreators';
import * as appActions from 'store/actions';

import Truck from 'core/entities/Truck/types';
import {getLatestPartnerTruckDataRequest} from 'core/gateways/TrucksNearbyApiGateway/requests';

import {ROUTES_PATHS} from 'routing/constants';

import * as modalActions from 'components/ui/ModalProvider/actions';

import {updatePartnerTruck} from 'pages/FullAlliance/redux/actions/list';

import * as types from '../actionTypes';
import {reserveActions} from '../constants';
import * as requests from '../requests';

export const setTruckId = (id) => (dispatch) => dispatch({type: types.SET_TRUCK_ID, payload: {id}});

const byTimeBaseReserve = (params) => () => {
    const {truck, reservationMinutes} = params;

    if (!reservationMinutes || !truck.id) {
        return;
    }

    const requestBody = {duration: reservationMinutes};

    return requests.baseByTimeReservationRequest({truckID: truck.id, requestBody});
};

const transferBaseReserve = (params) => () => {
    const {truck, transferDispatcher} = params;

    if (!transferDispatcher || !truck.id) {
        return;
    }

    const requestBody = {dispatcherId: transferDispatcher};

    return requests.baseTransferReservationRequest({truckID: truck.id, requestBody});
};

const holdForRCBaseReserve = (params) => () => {
    const {truck} = params;

    if (!truck.id) {
        return;
    }

    return requests.baseHoldForRCReservationRequest({truckID: truck.id});
};

const baseReserve = (params) => async (dispatch) => {
    const {isCloseOnlyReserveModalOnSuccess, reserveAction, onClose} = params;

    if (isCloseOnlyReserveModalOnSuccess) {
        onClose();
    }

    if (!isCloseOnlyReserveModalOnSuccess) {
        dispatch(modalActions.closeAll());
    }

    try {
        let response: AxiosResponse<Truck['reserve']> | null = null;
        dispatch(appActions.showLoader());

        if (reserveAction === reserveActions.transfer) {
            response = await dispatch(transferBaseReserve(params));
        }

        if (reserveAction === reserveActions.reserveForTime) {
            response = await dispatch(byTimeBaseReserve(params));
        }

        if (reserveAction === reserveActions.holdForRC) {
            response = await dispatch(holdForRCBaseReserve(params));
        }

        const requiredKeys = ['dateFrom', 'dateTo', 'reserver', 'holdForRc', 'secondsLeft'];

        if (response?.data && intersection((keys(response.data), requiredKeys)).length === requiredKeys.length) {
            const {dateFrom, dateTo, reserver, holdForRc, secondsLeft} = response.data;

            dispatch(
                commonTruckReserveUpdated({
                    id: params.truck.id,
                    reserve: {
                        dateFrom,
                        dateTo,
                        reserver,
                        holdForRc,
                        secondsLeft,
                    },
                }),
            );
        }
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const holdForRCCrossServerReserve = (params) => () => {
    const {truck} = params;

    const {id: truckID, carrier} = truck || {};

    return requests.crossServerHoldForRCReservationRequest({carrierID: carrier?.global_id, truckID});
};

const byTimeCrossServerReserve = (params) => () => {
    const {truck, reservationMinutes} = params;

    const {id: truckID, carrier} = truck || {};

    const requestBody = {duration: reservationMinutes};

    return requests.crossServerByTimeReservationRequest({
        carrierID: carrier?.global_id,
        truckID,
        requestBody,
    });
};

const crossServerReserve = (params) => async (dispatch, getState) => {
    const {reserveAction} = params;

    const state = getState();

    const carrierId = params?.truck?.carrier?.global_id;
    const truckID = params?.truck?.id;

    try {
        dispatch(modalActions.closeAll());
        dispatch(appActions.showLoader());

        if (reserveAction === reserveActions.reserveForTime) {
            const response = await dispatch(byTimeCrossServerReserve(params));

            dispatch(completeCrossServerTruckReservation({...response.data, carrierId}));
        }

        if (reserveAction === reserveActions.holdForRC) {
            const response = await dispatch(holdForRCCrossServerReserve(params));

            dispatch(completeCrossServerTruckReservation({...response.data, carrierId}));
        }
    } catch (error) {
        if (ROUTES_PATHS.fullAlliance === state.router.location.pathname) {
            const {data} = await getLatestPartnerTruckDataRequest({truckID});

            dispatch(updatePartnerTruck({truck: data}));
        } else {
            dispatch(appActions.handleError(error));
        }
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const reserve = (params) => (dispatch) => {
    const {isCrossServerReserving} = params;
    console.log('🚀 ~ isCrossServerReserving:', isCrossServerReserving);

    return isCrossServerReserving ? dispatch(crossServerReserve(params)) : dispatch(baseReserve(params));
};

const baseCancelReservation = (params) => async (dispatch) => {
    const {truck, isCloseOnlyReserveModalOnSuccess, onClose} = params;

    if (isCloseOnlyReserveModalOnSuccess) {
        onClose();
    }

    if (!isCloseOnlyReserveModalOnSuccess) {
        dispatch(modalActions.closeAll());
    }

    try {
        dispatch(appActions.showLoader());

        await requests.baseTruckCancelReservationRequest({truckID: truck.id});
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const crossServerCancelReservation = (params) => async (dispatch) => {
    const {truck} = params;

    const {id: truckID, carrier} = truck || {};

    try {
        dispatch(modalActions.closeAll());
        dispatch(appActions.showLoader());

        await requests.crossServerTruckCancelReservationRequest({carrierID: carrier?.global_id, truckID});

        dispatch(cancelCrossServerTruckReservation({truckId: truckID, carrierId: carrier?.global_id}));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const cancelReservation = (params) => (dispatch) => {
    const {isCrossServerReserving} = params;

    return isCrossServerReserving
        ? dispatch(crossServerCancelReservation(params))
        : dispatch(baseCancelReservation(params));
};
