import isEmpty from 'lodash/isEmpty';

import {getTruckDrivers} from 'core/entities/Truck/modules/truckDrivers';
import Truck from 'core/entities/Truck/types';
import {DispatcherNoteApi, Note} from 'core/entities/Truck/types/Note';

interface Result {
    dispatcherNote: Note[];
    firstDriverNote: Note[];
}

export const convertDispatcherNote = (note: DispatcherNoteApi): Note => ({
    dispatcher: note.dispatcher_note_creator,
    post_date: note.dispatcher_note_updated_at,
    text: note.dispatcher_note,
    type: 'dispatcher',
});

export const getDispatcherDriverTrucksNotes = (truck: Truck): Result => {
    const dispatcherNote = (): Note[] => {
        if (isEmpty(truck.dispatcher_note)) {
            return [];
        }
        return [
            {
                dispatcher: truck.dispatcher_note_creator,
                post_date: truck.dispatcher_note_updated_at,
                text: truck.dispatcher_note,
                type: 'dispatcher',
            },
        ];
    };
    const {firstDriver} = getTruckDrivers(truck) || {};

    const firstDriverNote = (): Note[] => {
        if (isEmpty(firstDriver?.driver_note)) {
            return [];
        }

        return [
            {
                dispatcher: undefined,
                firstDriver,
                post_date: firstDriver?.driver_note_updated_at,
                text: firstDriver?.driver_note,
                type: 'firstDriver',
            },
        ];
    };

    return {
        dispatcherNote: dispatcherNote(),
        firstDriverNote: firstDriverNote(),
    };
};
