import React from 'react';

import {getCarrierPhysicalAddressAllInfo} from 'core/entities/Carrier/modules/carrierAddress';
import Carrier from 'core/entities/Carrier/types';

import {phoneNumberWithBraces} from 'utils';
import {getCityLineFromObject} from 'utils/getCityLine';

import styles from './styles.module.scss';

type OwnProps = {
    company: Carrier;
};

const WorkWithCompaniesTableRow: React.FC<OwnProps> = (props) => {
    const {company} = props;

    const {
        carrierPhysicalCountry,
        carrierPhysicalState,
        carrierPhysicalCity,
        carrierPhysicalZip,
    } = getCarrierPhysicalAddressAllInfo(company);

    const physicalAddress = getCityLineFromObject({
        country: carrierPhysicalCountry,
        state: carrierPhysicalState,
        city: carrierPhysicalCity,
        zip: carrierPhysicalZip,
    });

    return (
        <tr>
            <td>
                <div className={styles.name}>{company.company_name}</div>
            </td>

            <td>
                <div className={styles.prefix}>{company.prefix}</div>
            </td>

            <td>
                <div>{phoneNumberWithBraces(company.phone)}</div>
            </td>

            <td>
                <div className={styles.address}>{physicalAddress}</div>
            </td>
        </tr>
    );
};

export default WorkWithCompaniesTableRow;
