import Owner from 'core/entities/Owner/types';

import {LIST_TABS} from 'pages/Owners/constants/tabs';

import Pagination from 'types/Pagination';

import * as types from '../actionTypes';

type ListState = {
    searchParams: {[key: string]: any};
    pagination: Partial<Pagination>;
    data: Owner[];
    activeListTab: keyof typeof LIST_TABS;
};

const defaultState: ListState = {
    pagination: {currentPage: 1, perPage: 50},
    searchParams: {},
    data: [],
    activeListTab: LIST_TABS.GENERAL,
};

const list = (state = defaultState, action): ListState => {
    switch (action.type) {
        case types.OWNERS_RECEIVED: {
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
            };
        }

        case types.OWNER_ARCHIVED: {
            const {owner: archivedOwner} = action.payload;
            return {
                ...state,
                data: state.data.filter((owner) => owner.id !== archivedOwner.id),
            };
        }

        case types.OWNERS_SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload,
            };
        }

        case types.OWNERS_SET_CURRENT_PAGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload,
                },
            };
        }

        case types.OWNERS_SET_PAGINATION: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };
        }

        case types.SET_ACTIVE_LIST_TAB: {
            return {
                ...state,
                activeListTab: action.payload,
            };
        }

        case types.CLEAR_STATE: {
            return {...defaultState, activeListTab: state.activeListTab};
        }

        default:
            return state;
    }
};

export default list;
