import React from 'react';

type OwnProps = {
    toProfileLabel: string;
    entityName: string;
    isAccountRestored?: boolean;
};

const SuccessFormRestore: React.FC<OwnProps> = (props) => {
    const {toProfileLabel, entityName, isAccountRestored} = props;
    const withAccountText = isAccountRestored ? 'and mobile application account ' : '';
    const message = (
        <>
            <strong>{toProfileLabel}</strong>&nbsp;
            {entityName}&rsquo;s profile {withAccountText}has been restored successfully.
        </>
    );

    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-check feedback-icon-success" />
            </div>
            <div className="col-11">
                <p>{message}</p>
            </div>
        </div>
    );
};

export default SuccessFormRestore;
