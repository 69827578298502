import {convertToLbs} from 'utils/weightConverter';

import truckApiGateway from '../../gateways/TruckApiGateway';

import createTruckUseCase from './createTruck';
import getTruckUseCase from './getTruck';
import searchTrucksOnMapUseCase from './searchTrucksOnMap';
import type {CreateTruckDTO, GetTruckDTO, SearchTrucksOnMapDTO, UpdateTruckDTO} from './types';
import updateTruckUseCase from './updateTruck';

export const getTruck = (data: GetTruckDTO): ReturnType<typeof getTruckUseCase> => {
    return getTruckUseCase(data, {truckApiGateway});
};

export const createTruck = (data: CreateTruckDTO): ReturnType<typeof createTruckUseCase> => {
    return createTruckUseCase(data, {truckApiGateway, lbsConverter: convertToLbs});
};

export const updateTruck = (data: UpdateTruckDTO): ReturnType<typeof updateTruckUseCase> => {
    return updateTruckUseCase(data, {truckApiGateway, lbsConverter: convertToLbs});
};

export const searchTruckOnMap = (data: SearchTrucksOnMapDTO): ReturnType<typeof searchTrucksOnMapUseCase> => {
    return searchTrucksOnMapUseCase(data, {truckApiGateway});
};
