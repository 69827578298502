import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

import Carrier from 'core/entities/Carrier/types';

import {SELECT_COMPANIES_FORM_NAME} from 'pages/Trucks/constants';

import useGetReduxFormInitialValues from 'hooks/reduxForm/useGetReduxFormInitialValues';

import useSelectCompaniesState from './useSelectCompaniesState';

type SyncItem = {label: string; value: number; hasActiveLoad?: false} | null;

type UseSyncTableRows = {
    isCompanyLoadPlannedOrInProgress: boolean;
    clearAllSelectedCompanies: SyncItem[];
    selectAllCompanies: SyncItem[];
};

const useSyncTableRows = (company?: Carrier): UseSyncTableRows => {
    const {selectedCompanies} = useGetReduxFormInitialValues(SELECT_COMPANIES_FORM_NAME);
    const {initialCompanies} = useSelectCompaniesState();

    const selectedCompaniesIDs = keyBy(selectedCompanies, 'value');

    const selectAllCompanies = map(initialCompanies, ({id, company_name}, idx) => {
        return {
            label: company_name,
            value: id,
            hasActiveLoad: selectedCompanies?.[idx]?.hasActiveLoad,
        };
    });

    const clearAllSelectedCompanies = map(selectedCompanies, (item) => {
        if (item?.hasActiveLoad) {
            return {
                label: item?.label,
                value: item?.value,
                hasActiveLoad: item?.hasActiveLoad,
            };
        }

        return null;
    });

    const checkIsCompanyLoadPlannedOrInProgress = () => {
        if (!company) {
            return false;
        }

        return selectedCompaniesIDs[company.id]?.hasActiveLoad;
    };

    return {
        isCompanyLoadPlannedOrInProgress: checkIsCompanyLoadPlannedOrInProgress(),
        clearAllSelectedCompanies,
        selectAllCompanies,
    };
};

export default useSyncTableRows;
