import {useSelector} from 'react-redux';

import * as selectors from 'widgets/Chat/redux/selectors';

const useChatState = () => ({
    driversGroupUnreadMessages: useSelector(selectors.getDriversGroupUnreadMessages),
    isAttachmentsUploading: useSelector(selectors.checkIsAttachmentsUploading),
    isGroupMessagesLoaded: useSelector(selectors.getIsGroupMessagesLoaded),
    unreadMessagesCount: useSelector(selectors.getUnreadMessagesCount),
    openedDriversGroup: useSelector(selectors.getOpenedDriversGroup),
    chatClientSocketID: useSelector(selectors.getChatClientSocketID),
    messageTextToFill: useSelector(selectors.getMessageTextToFill),
    chatDispatcher: useSelector(selectors.getChatDispatcher),
    driversGroups: useSelector(selectors.getDriversGroups),
    searchParams: useSelector(selectors.getSearchParams),
    isChatOpen: useSelector(selectors.checkIsChatOpen),
    pagination: useSelector(selectors.getPagination),
    unreadInfo: useSelector(selectors.getUnreadInfo),
    serverID: useSelector(selectors.getServerID),
    truck: useSelector(selectors.getOpenedTruck),

    HR_SERVER_ID_EU: useSelector(selectors.getHRServerIDEU),
    HR_SERVER_ID_NA: useSelector(selectors.getHRServerIDNA),
    SERVER_IDS_EU: useSelector(selectors.getServerIDSEU),

    isMessageStatusSending: useSelector(selectors.checkIsMessageStatusSending),
    isMessageStatusSuccess: useSelector(selectors.checkIsMessageStatusSuccess),
    isMessageStatusFailure: useSelector(selectors.checkIsMessageStatusFailure),
    isMessageStatusIdle: useSelector(selectors.checkIsMessageStatusIdle),
    chatMessageStatus: useSelector(selectors.getMessageStatus),
});

export default useChatState;
