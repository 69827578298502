import isEmpty from 'lodash/isEmpty';

import Truck from 'core/entities/Truck/types';

import {ListState} from 'pages/TrucksNearby/redux/reducers/list';

export const handleReducerByReceivedSSEData = (params: {state: ListState; trucks: Truck[]}) => {
    const {state, trucks} = params;

    if (isEmpty(state.items) || isEmpty(trucks)) {
        return state;
    }

    const updatedTrucksById = trucks.reduce((obj, truck) => {
        obj[truck.id] = truck;
        return obj;
    }, {});
    const updatedItems = state.items.map((item) => {
        const updatedTruck = updatedTrucksById[item.id];

        if (updatedTruck) {
            return updatedTruck;
        }

        return item;
    });

    return {
        ...state,
        items: updatedItems,
    };
};
