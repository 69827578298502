import config from 'config';

import {isNewStoredFile, isStoredFile} from 'utils/files';

import {File as StoredFile, FileNew as StoredFileNew, TOFile} from 'types/File';

const getFilePath = (file?: StoredFile | StoredFileNew | TOFile | null): string => {
    if (!file) {
        return '';
    }

    if (isNewStoredFile(file)) {
        const {filePath, fileName} = file;
        return `${config.PUBLIC_URL}/web/${filePath}${fileName}`;
    }

    if (isStoredFile(file)) {
        const {file_url, file_name} = file;
        return `${config.PUBLIC_URL}/web/${file_url}${file_name}`;
    }

    return '';
};

export default getFilePath;
