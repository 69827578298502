import {useDispatch} from 'react-redux';

import Truck from 'core/entities/Truck/types';

import * as modalActions from 'components/ui/ModalProvider/actions';

import {modalNames} from '../modals/modalMap';

type Params = {
    truck: Truck;
    isNeedGeneralNotes?: boolean;
    isDisableDriverLinks?: boolean;
    isNeedDispatcherEmail: boolean;
    isCrossServerReserving: boolean;
    isDisableDispatcherLinks: boolean;
    isDisableReservedByLink?: boolean;
    isCloseOnlyReserveModalOnSuccess: boolean;
};

const useShowReserveAction = (params: Params) => {
    const {
        truck,
        isNeedGeneralNotes,
        isDisableDriverLinks,
        isNeedDispatcherEmail,
        isCrossServerReserving,
        isDisableReservedByLink,
        isDisableDispatcherLinks,
        isCloseOnlyReserveModalOnSuccess,
    } = params;
    const dispatch = useDispatch();

    const showModalAction = () =>
        modalActions.openModal({
            modalName: modalNames.truckReserving,
            data: {
                truck,
                isNeedGeneralNotes,
                isDisableDriverLinks,
                isNeedDispatcherEmail,
                isCrossServerReserving,
                isDisableReservedByLink,
                isDisableDispatcherLinks,
                isCloseOnlyReserveModalOnSuccess,
            },
        });

    return () => dispatch(showModalAction());
};

export default useShowReserveAction;
