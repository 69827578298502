import has from 'lodash/has';
import {Dispatch} from 'redux';

import * as appModalActions from 'store/actions/modalActions';
import {getPermissions} from 'store/selectors';

import {getDriverName} from 'core/entities/Driver/modules/driverName';
import Driver from 'core/entities/Driver/types';
import {PERMISSIONS} from 'core/entities/Permissions';

import {openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import {modalNames} from 'pages/Drivers/components/modals/modalMap';
import {LIST_TABS} from 'pages/Drivers/constants/tabs';

import {ENTITY_NAME_DRIVER} from 'utils/data/entityNames';

import {getCurrentDriver} from '../selectors';

import {navigateToUpdatePage} from './archiving';
import {addNote, deleteDriverGeneralNote, passwordSubmit, restoreDriverAccountSubmit} from './current';
import {setActiveListTab} from './list';

const showErrorModal = (params: {action: 'create' | 'restore'}) => {
    return (dispatch) => {
        const {action} = params;
        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: 'Error',
                    rightButtonTitle: 'ok',
                    errorMessage: `You cannot ${action} driver because you have not chosen owner.`,
                    bodyType: 'ErrorForm',
                    buttonType: 'danger',
                },
            }),
        );
    };
};

export const showDuplicateModal = (driver) => {
    return (dispatch: Dispatch) => {
        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: 'Error',
                    entity: driver,
                    entityName: ENTITY_NAME_DRIVER,
                    rightButtonTitle: 'ok',
                    bodyType: 'ErrorForm',
                    buttonType: 'danger',
                },
            }),
        );
    };
};

export const showCreateDriverWithoutOwnerModal = () => {
    return (dispatch) => {
        dispatch(showErrorModal({action: 'create'}));
    };
};

export const showRestoreDriverWithoutOwnerModal = () => {
    return (dispatch) => {
        dispatch(showErrorModal({action: 'restore'}));
    };
};

type ShowSuccessModalParams = {
    driver: Driver;
    isAccountRestored?: boolean;
    action: 'created' | 'updated' | 'restored';
};
export const showSuccessModal = (params: ShowSuccessModalParams) => {
    return (dispatch) => {
        const {driver, action, isAccountRestored} = params;
        const successAction = appModalActions.showEntityActionSuccess({
            entity: driver,
            entityName: ENTITY_NAME_DRIVER,
            entityLabel: getDriverName(driver),
            onGoToList: () => dispatch(setActiveListTab(LIST_TABS.GENERAL)),
            action,
            isAccountRestored,
        });

        dispatch(successAction);
    };
};

export const showPasswordChangeModal = () => {
    return (dispatch, getState) => {
        const driver = getCurrentDriver(getState());

        dispatch(
            openModal({
                modalName: modalNames.passwordModal,
                data: {entity: driver},
                handlers: {
                    handleSubmit: ({fields}) => dispatch(passwordSubmit(driver.id, fields)),
                },
            }),
        );
    };
};

export const showRestoreDriverAccountModal = () => {
    return (dispatch, getState) => {
        const driver = getCurrentDriver(getState());

        dispatch(
            openModal({
                modalName: modalNames.restoreDriverAccountModal,
                data: {driver},
                handlers: {
                    onConfirm: () => {
                        dispatch(restoreDriverAccountSubmit({driverID: driver.id}));
                    },
                },
            }),
        );
    };
};

export const showRestoreDriverProfileModal = (listDriver?: Driver) => {
    return (dispatch, getState) => {
        const driver = listDriver || (getCurrentDriver(getState()) as Driver);

        dispatch(
            openModal({
                modalName: modalNames.restoreDriverProfileModal,
                data: {driver},
                handlers: {
                    onConfirm: () => {
                        dispatch(navigateToUpdatePage(driver));
                    },
                },
            }),
        );
    };
};

export const showGeneralNotesModal = () => {
    return (dispatch, getState) => {
        const state = getState();
        const driver = getCurrentDriver(state);
        const title = `Driver ${getDriverName(driver)} Notes`;
        const permissions = getPermissions(state);
        const addNoteDisabled = !has(permissions, PERMISSIONS.DRIVER_NOTES_UPDATE);
        const deleteNote = (noteID: string) => dispatch(deleteDriverGeneralNote(noteID));

        dispatch(
            openModal({
                modalName: commonModalNames.generalNotes,
                data: {
                    title,
                    deleteNote,
                    entity: driver,
                    addNoteDisabled,
                    deletePermissionName: PERMISSIONS.DRIVER_NOTES_UPDATE,
                },
                handlers: {
                    onSubmit: (note) => dispatch(addNote(note)),
                },
                selectors: {
                    notesSelector: (appState) => getCurrentDriver(appState).generalNotes,
                },
            }),
        );
    };
};
