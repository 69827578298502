import {AppState} from 'store';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/selectors';

import Owner from 'core/entities/Owner/types';

import {ENTITY_NAME_OWNERS} from 'utils/data/entityNames';

import {GroupedFiles} from 'types/File';

// current
export const getCurrentOwner = (state): (Owner & {files: GroupedFiles}) | undefined => state.owners?.current?.data;
export const getShowSuccessModal = (state) => state.owners.current.showSuccessModal;
export const getShowUpdateModal = (state) => state.owners.current.showUpdateModal;
export const getFormActiveTab = (state) => state.owners.current.activeTab;

// current: companies tab
export const getCompaniesTabPagination = (state: AppState) => state.owners.current.companiesTab.pagination;
export const getCompaniesTabItemsOrderBy = (state: AppState) => state.owners.current.companiesTab.orderBy;
export const getCompaniesTabItems = (state: AppState) => state.owners.current.companiesTab.items;

// list
export const getListSearchParams = (state: AppState) => state.owners.list.searchParams;
export const getListPagination = (state: AppState) => state.owners.list.pagination;
export const getListOwners = (state: AppState) => state.owners.list.data;
export const getActiveListTab = (state: AppState) => state.owners.list.activeListTab;

// sorting
export const getOwnersSortFrom = (state: AppState) => getCurrentSortFrom(state, ENTITY_NAME_OWNERS);
export const getOwnersSortBy = (state: AppState) => getCurrentSortBy(state, ENTITY_NAME_OWNERS);
