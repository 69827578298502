import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import {getDriverName} from 'core/entities/Driver/modules/driverName';
import {Note} from 'core/entities/Truck/types/Note';

import appConfig from 'config';

import {DispatcherLink, DriverLink} from 'components/ui/Links';

import {createDate} from 'utils/dateTime';
import decodeText from 'utils/decodeText';

import useCurrentDispatcher from 'hooks/useCurrentDispatcher';
import useDomainSettings from 'hooks/useDomainSettings';

import {NoteType, Tab} from '../../types';

import styles from './notesList.module.scss';

interface OwnProps {
    tabsName: Tab;
    notes: Note[];
    children: JSX.Element;
    isDisableDispatcherLinks?: boolean;
    isDisableDriverLinks?: boolean;
}

const listDescribe: {[key in NoteType]: string} = {
    firstDriver: "First driver's note",
    dispatcher: "Dispatcher's note",
    general: 'General note',
};

const listDescribeForEmptyTabs: {[key in Tab]: string} = {
    all: 'notes',
    firstDriver: "First driver's note",
    dispatcher: "Dispatcher's note",
    general: 'General note',
};

const getTypeNoteDescribe = (type: NoteType, describe: {[key in NoteType]: string}): string => {
    return describe[type] || 'General note';
};

const getUserNoteCompanyName = (note: Note): string => {
    if (note.type === 'firstDriver') {
        return note.firstDriver?.companyName || '';
    }
    return note?.dispatcher?.company_name || '';
};

const getDescribeEmptyNoteInTab = (tab: Tab, describe: {[key in Tab]: string}): string => {
    return describe[tab];
};

const NoteItem = ({note, children, isDisableDispatcherLinks, isDisableDriverLinks}): JSX.Element => {
    const {isDispatchlandRecruitingAgency} = useDomainSettings();
    const {currentDispatcher} = useCurrentDispatcher();

    const timeOfCreate = (note.post_date && createDate(note.post_date).fullDate) || null;
    const noteDescribeType = getTypeNoteDescribe(note.type, listDescribe);
    const noteSplitInLines = note.text && decodeText(note.text).split('\n');
    const noteUserCompanyName = getUserNoteCompanyName(note);
    const isUserFromDifferentServer =
        (!isDispatchlandRecruitingAgency && noteUserCompanyName !== currentDispatcher?.company?.name) ||
        (isDispatchlandRecruitingAgency && note?.dispatcher?.prefix !== appConfig.REACT_APP_PREFIX);

    const dispatcherLinkJSX = !isEmpty(note.dispatcher) ? (
        <DispatcherLink
            content={getDispatcherName(note.dispatcher)}
            disabled={isDisableDispatcherLinks || isUserFromDifferentServer}
            dispatcher={note.dispatcher}
            className="second-link"
        />
    ) : null;

    const driverLinkJSX = !isEmpty(note?.firstDriver) ? (
        <DriverLink
            content={getDriverName(note.firstDriver)}
            disabled={isDisableDriverLinks}
            driver={note.firstDriver}
            className="second-link"
        />
    ) : null;

    return (
        <div className={classNames(styles.item)}>
            <div className={styles.header}>
                <div className={styles.desc}>
                    <div className={styles.type}>
                        <span>{noteDescribeType}: </span>
                    </div>
                    <div className={styles.time}>
                        <span>{timeOfCreate}</span>{' '}
                        {!isUserFromDifferentServer && !note.type && React.cloneElement(children, {note})}
                    </div>
                </div>
                <div className={styles.link}>
                    <strong>{dispatcherLinkJSX || driverLinkJSX}</strong>
                    {noteUserCompanyName ? (
                        <strong className={styles.companyName}>{` | ${noteUserCompanyName}`}</strong>
                    ) : null}
                </div>
            </div>

            <div className={styles.content}>
                {noteSplitInLines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        </div>
    );
};

const NotesList = (props: OwnProps): JSX.Element => {
    const {notes, tabsName, children, isDisableDispatcherLinks, isDisableDriverLinks} = props;

    if (isEmpty(notes)) {
        const textTabsName = getDescribeEmptyNoteInTab(tabsName, listDescribeForEmptyTabs);

        return (
            <div className={styles.empty_notes}>
                The current truck doesn&#8217;t have <strong>{textTabsName}</strong>.
            </div>
        );
    }

    return (
        <div className={styles.list}>
            {notes.map((note, index) => (
                <NoteItem
                    isDisableDispatcherLinks={isDisableDispatcherLinks}
                    isDisableDriverLinks={isDisableDriverLinks}
                    note={note}
                    key={index}
                >
                    {children}
                </NoteItem>
            ))}
        </div>
    );
};

export default NotesList;
