import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import {getTruckCompaniesOrderBy} from 'pages/TrucksNearby/redux/selectors/workWithCompanies';

const ORDER_MAP = {
    null: 'asc',
    asc: 'desc',
    desc: null,
};

export const transformDataToRequestBody = (params: {state}) => {
    const {state} = params;

    const orderBy = getTruckCompaniesOrderBy(state);

    const nextSort = ORDER_MAP[String(orderBy)];

    const searchParams = {
        sortBy: nextSort ? {company_name: nextSort} : null,
    };

    return omitBy(searchParams, (value) => isNull(value) || isUndefined(value));
};

export const handleReducerByWorkWithCompaniesByOrderByReceived = (params: {state; companies}) => {
    const {state, companies} = params;

    return {
        ...state,
        companies,
        orderBy: ORDER_MAP[state?.orderBy],
    };
};
