import React from 'react';

type OwnProps = {
    toProfileLabel: string;
    entityName: string;
    customEntityName?: string;
};

const RestoreForm: React.FC<OwnProps> = ({entityName, toProfileLabel}) => {
    return (
        <div className="row align-items-center">
            <div className="col-1">
                <i className="fa fa-exclamation feedback-icon-warning" />
            </div>
            <div className="col-11">
                <div>
                    <strong>DO YOU WANT TO RESTORE THE {entityName?.toUpperCase()}?</strong>
                </div>
                <p>
                    Restoring <strong>{toProfileLabel}</strong>&nbsp;
                    {entityName}&rsquo;s profile will activate all possible actions&nbsp;with&nbsp;it.
                </p>
            </div>
        </div>
    );
};

export default RestoreForm;
