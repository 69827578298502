import {toast} from 'react-toastify';
import {AxiosPromise, AxiosRequestConfig, AxiosResponse} from 'axios';
import isEmpty from 'lodash/isEmpty';

import Owner from 'core/entities/Owner/types';

import restapi, {fetchFiles, postFiles} from 'services/restapi';
import {getGeneralNotes, postGeneralNote} from 'services/restapi/generalNotes';

import notifications from 'components/ui/notifications';

import {ENTITY_NAME_OWNERS} from 'utils/data/entityNames';
import formatFileExtraData from 'utils/files/formatFileExtraData';
import groupFilesByType from 'utils/files/groupFilesByType';

import StoredFile, {GroupedFiles} from 'types/File';
import GeneralNote from 'types/GeneralNote';

const apiUrl = `/${ENTITY_NAME_OWNERS}`;

export const searchOwners = ({requestPayload}): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/search`, requestPayload);
};

export const fetchOwnersList = (params): Promise<AxiosResponse> => {
    return restapi.get(apiUrl, {params});
};

export const fetchOwner = (ownerID: string): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/${ownerID}`);
};

export const updateOwner = (owner: Owner): Promise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${owner.id}`, owner);
};

export const createOwner = (owner: Owner): Promise<AxiosResponse> => {
    return restapi.post(apiUrl, owner);
};

export const archiveOwner = (owner: Owner): Promise<Owner> => {
    return restapi.put(`${apiUrl}/${owner.id}/archive`).then(() => ({...owner, is_deleted: true}));
};

export const restoreOwner = (owner: Owner): Promise<Owner> => {
    return restapi.put(`${apiUrl}/${owner.id}/restore`).then(() => ({...owner, is_deleted: false}));
};

export const searchOwnerTrucks = (ownerId: string, params = {}): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/${ownerId}/trucks`, params);
};

export const searchOwnerDrivers = (ownerId: string): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/${ownerId}/drivers`);
};

export const searchOwnersByTitle = (params): Promise<AxiosResponse> => {
    return restapi.post(`${apiUrl}/title/search`, params);
};

export const searchDriverDuplicates = (owner: Owner): Promise<Owner | undefined> => {
    const {mobile_phone, full_name} = owner;

    const requestPayload = {mobile_phone, full_name};

    return searchOwners({requestPayload}).then((response): Owner | undefined => {
        const {data: owners} = response;
        if (isEmpty(owners)) {
            return;
        }

        return owners.find((item: Owner) => {
            return item.mobile_phone === mobile_phone && item.full_name.toLowerCase() === full_name.toLowerCase();
        });
    });
};

export const updateOwnerPassword = (
    ownerID: string,
    data: {email: string; new_password: string; confirm_password: string},
): AxiosPromise<AxiosResponse> => {
    return restapi.put(`${apiUrl}/${ownerID}/password`, data);
};

export const getOwnerFilesById = (id: string): Promise<StoredFile[]> => {
    const params = {
        id,
        entity: ENTITY_NAME_OWNERS,
    };
    return fetchFiles(params).then((res) => {
        if ('data' in res) {
            return res.data;
        }
    });
};

export const fetchOwnerFiles = (ownerId: string): AxiosPromise<GroupedFiles> =>
    fetchFiles({
        id: ownerId,
        entity: ENTITY_NAME_OWNERS,
    }).then((res) => {
        if ('data' in res) {
            return groupFilesByType(res.data);
        }
    });

export const postOwnerGeneralNote = (ownerID, text) => {
    return postGeneralNote({entity_name: ENTITY_NAME_OWNERS, entity_id: ownerID, text}).then(({data}) => data);
};

export const postOwnerFiles = (formData) => {
    const {id: ownerId, new_files} = formData;
    if (isEmpty(new_files)) {
        return Promise.resolve();
    }

    return Object.entries(new_files).map(([key, value]: [string, any]) => {
        if (isEmpty(value)) {
            return null;
        }
        return postFiles(
            value.map((wrappedFile) => wrappedFile.file),
            {
                id: ownerId,
                entity: ENTITY_NAME_OWNERS,
                type: key,
                extraData: value.map((item) => formatFileExtraData(item)),
            },
        )
            .then((res: any) => {
                toast.success(notifications.filesSuccessfulAdded, {data: {}});

                if ('data' in res) {
                    return res.data;
                }
            })
            .catch(() => {
                toast.error(notifications.filesUnsuccessfulAdded, {data: {}});
            });
    });
};

export const getGroupedDriverFiles = (id: string): Promise<GroupedFiles> => {
    return getOwnerFilesById(id).then((files: StoredFile[]): GroupedFiles => groupFilesByType(files));
};

export const fetchOwnerGeneralNotes = (ownerID: string): Promise<GeneralNote[]> => {
    return getGeneralNotes({entity_name: ENTITY_NAME_OWNERS, entity_id: ownerID}).then(({data}) => data);
};

export const searchOwnerByName = (fullName: string): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/find/duplicate-by/full-name?fullName=${fullName}`);
};

export const searchOwnerByCompanyName = (companyName: string): Promise<AxiosResponse> => {
    return restapi.get(`${apiUrl}/find/duplicate-by/company-name?companyName=${companyName}`);
};

type OwnerHiddenData = {
    coordinator_mobile_phone?: string;
    mobile_phone: string;
    email: string;
    id: string;
};

export const revealOwnerHiddenData = (
    {ownerID}: {ownerID: string},
    config?: AxiosRequestConfig,
): Promise<AxiosPromise<OwnerHiddenData[]>> => {
    return restapi.get(`${apiUrl}/${ownerID}/hidden-data`, config);
};
