import type {GetTruckDTO, GetTruckResultDTO, TruckApiGateway} from '../types';

type Deps = {
    truckApiGateway: TruckApiGateway;
};

const getTruck = async function (data: GetTruckDTO, deps: Deps): Promise<GetTruckResultDTO> {
    const {truckNumber} = data;
    const {truckApiGateway} = deps;
    const truck = await truckApiGateway.getTruck(truckNumber);
    const [truckOwner, truckFiles, truckNotes] = await Promise.all([
        truckApiGateway.getTruckOwner(truck),
        truckApiGateway.getTruckFiles(truck.id),
        truckApiGateway.getTruckNotes(truck.id),
    ]);

    return {
        // replace owner cause in general truck owner doesnt have deep data
        truck: {...truck, owner: truckOwner},
        truckNotes,
        truckFiles,
    };
};

export default getTruck;
