import isEmpty from 'lodash/isEmpty';

import {COMMON_TRUCK_RESERVE_UPDATED} from 'store/actionTypes';
import * as websocketsTypes from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import * as types from 'pages/Trucks/redux/actionTypes';
import {CurrentTruck, UpdateFormTab} from 'pages/Trucks/types';

import * as mappers from '../mappers';

interface State {
    truckData?: CurrentTruck;
    update: {
        activeTab?: UpdateFormTab;
    };
}

const defaultState: State = {
    truckData: undefined,
    update: {activeTab: 'general'},
};

export default function (state: State = defaultState, action): State {
    switch (action.type) {
        case types.TRUCK_RECEIVED: {
            return mappers.handleReducerByTruckReceived({state, truckData: action.payload.truckData});
        }

        case types.TRUCK_ARCHIVED: {
            const {truck: archivedTruck} = action.payload;
            if (!state.truckData) {
                return state;
            }
            return {
                ...state,
                truckData: {...state.truckData, ...archivedTruck},
            };
        }

        case types.TRUCK_NOTE_ADDED: {
            if (!state.truckData) {
                return state;
            }

            const newNote = action.payload;
            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    truck_notes: [newNote, ...state.truckData.truck_notes],
                },
            };
        }
        case types.TRUCK_NOTE_DELETED: {
            if (!state.truckData) {
                return state;
            }

            const {id: deletedNoteId} = action.payload;
            const {truck_notes} = state.truckData;
            const filteredNotes = truck_notes.filter((note) => note.id !== deletedNoteId);

            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    truck_notes: filteredNotes,
                },
            };
        }

        case types.TRUCK_DISPATCHER_NOTE_UPDATED: {
            const updatedTruck = action.payload;
            // for case when we have update notes from another page
            if (isEmpty(state.truckData)) {
                return state;
            }
            const {note} = updatedTruck;

            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    ...note,
                },
            };
        }

        case types.TRUCK_STATE_CLEAR:
            return defaultState;

        case websocketsTypes.WEB_SOCKET_TRUCKS_RESERVE_RECEIVED: {
            if (!state.truckData || action.payload.truckId !== state.truckData.id) {
                return state;
            }

            return {
                ...state,
                truckData: {...state.truckData, reserve: action.payload},
            };
        }

        case websocketsTypes.WEB_SOCKET_TRUCKS_RESERVE_CANCELED: {
            if (!state.truckData || action.payload !== state.truckData.id) {
                return state;
            }

            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    reserve: null,
                },
            };
        }

        case websocketsTypes.WEB_SOCKET_TRUCKS_UPDATE_RECEIVED: {
            if (!state.truckData || action.payload.id !== state.truckData.id) {
                return state;
            }
            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    ...action.payload,
                    dispatcher_note: action.payload.dispatcher_note || null,
                },
            };
        }

        case types.TRUCK_UPDATE_SET_ACTIVE_TAB: {
            return {
                ...state,
                update: {
                    ...state.update,
                    activeTab: action.payload,
                },
            };
        }

        case types.TRUCK_UPDATE_RESET_TAB: {
            return {...state, update: {...state.update, activeTab: 'general'}};
        }

        // COMMON

        case COMMON_TRUCK_RESERVE_UPDATED: {
            const {id, reserve} = action.payload;

            if (!state.truckData || id !== state.truckData?.id) {
                return state;
            }

            return {
                ...state,
                truckData: {
                    ...state.truckData,
                    reserve,
                },
            };
        }

        default:
            return state;
    }
}
