import React from 'react';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import appConfig from 'config';

import TruckReservingTimer from 'components/common/Truck/TruckReservingTimer';
import {DispatcherLink} from 'components/ui/Links';

import {createDate} from 'utils/dateTime';
import getText from 'utils/getText';

import useDomainSettings from 'hooks/useDomainSettings';
import {ReservingTimerContext} from 'hocs/withTruckReservingTimer/ReservingTimerContext';

const ReserveDate: React.FC<any> = (props) => {
    const {date} = props;

    if (!date) {
        return (
            <div>
                <div> on -- </div>
                <div> at -- </div>
            </div>
        );
    }

    const reserveDate = createDate(date);

    return (
        <div>
            on {reserveDate.date} at {reserveDate.time}
        </div>
    );
};

const ReservingInfo: React.FC<any> = (props) => {
    const {
        isDisableReservedByLink,
        isCrossServerReserving,
        isNeedDispatcherEmail,
        currentDispatcher,
        currentReserve,
        reserveType,
        waitingRC,
    } = props;

    const {isDispatchlandRecruitingAgency} = useDomainSettings();

    const rc = getText('rc');

    const isUserFromDifferentServer =
        (!isDispatchlandRecruitingAgency &&
            currentReserve?.reserver?.serverName !== currentDispatcher?.company?.name) ||
        (isDispatchlandRecruitingAgency && currentReserve?.reserver?.serverPrefix !== appConfig.REACT_APP_PREFIX);

    const isDispatcherLinkDisabled =
        isDisableReservedByLink || isCrossServerReserving || currentReserve?.external || isUserFromDifferentServer;

    if (reserveType.notReserved) {
        return (
            <div className="modal-body__not-reserve">
                <span className="reserving-label">available to reserve</span>
            </div>
        );
    }

    if (reserveType.reserved) {
        return (
            <div className="modal-body__reserve">
                <div>
                    <span className="reserving-label">Reserved by: </span>
                    <div className="reserving-data">
                        <span className="font-bold">
                            <DispatcherLink
                                disabled={isDispatcherLinkDisabled}
                                dispatcher={{id: currentReserve?.reserver?.dispatcherId, ...currentReserve?.reserver}}
                                content={getDispatcherName({full_name: currentReserve?.reserver?.dispatcherName})}
                                className="second-link"
                            />
                        </span>

                        {waitingRC ? (
                            <span>
                                {' '}
                                for <strong>{rc}</strong>
                            </span>
                        ) : null}

                        <div>
                            <ReserveDate date={currentReserve?.dateFrom} />
                        </div>

                        {isNeedDispatcherEmail ? (
                            <span className="ml5">({currentReserve?.reserver?.dispatcherEmail})</span>
                        ) : null}
                    </div>
                </div>
                <div>
                    <span className="reserving-label">expires: </span>
                    <div className="reserving-data">
                        <ReserveDate date={currentReserve?.dateTo} />
                        <ReservingTimerContext.Consumer>
                            {(timer) => (
                                <span>
                                    in ( <TruckReservingTimer timer={timer} /> )
                                </span>
                            )}
                        </ReservingTimerContext.Consumer>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default ReservingInfo;
