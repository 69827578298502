import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import {GENERAL_TRAILER_TYPES} from 'core/entities/Truck/constants/trailerTypes';
import {GENERAL_TRUCK_TYPES} from 'core/entities/Truck/constants/truckTypes';
import Truck from 'core/entities/Truck/types';

const reeferTypes = [
    // truck types
    GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK_REEFER,
    GENERAL_TRUCK_TYPES.BOX_TRUCK_REEFER,
    GENERAL_TRUCK_TYPES.ISOTHERM_TRUCK,
    GENERAL_TRUCK_TYPES.REEFER_VAN,

    // trailer types
    GENERAL_TRAILER_TYPES.REEFER_TRAILER,
    GENERAL_TRAILER_TYPES.ISOTHERM,
];

const reeferTruckTypes = [
    GENERAL_TRUCK_TYPES.REEFER_VAN,
    GENERAL_TRUCK_TYPES.BOX_TRUCK_REEFER,
    GENERAL_TRUCK_TYPES.STRAIGHT_TRUCK_REEFER,
];

export const isReeferTruck = (truck: Truck): boolean => {
    const truckTypes = compact([truck.type, truck.trailer_type]);

    const reeferOptions = reeferTypes.filter((type) => truckTypes.includes(type));

    return !isEmpty(reeferOptions);
};

export const isReeferTruckType = (type) => reeferTruckTypes.includes(type);
