import React, {ReactNode} from 'react';
import {Modal} from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import Button from 'components/ui/Buttons/Button';
import {LinksMap} from 'components/ui/Links';

import ArchiveForm from './modalBodies/ArchiveForm';
import CancelForm from './modalBodies/CancelForm';
import ClearAllFieldsForm from './modalBodies/ClearAllFieldsForm';
import ErrorForm from './modalBodies/ErrorForm';
import ResetFieldsForm from './modalBodies/ResetFieldsForm';
import RestoreForm from './modalBodies/RestoreForm';
import Success from './modalBodies/Success';
import SuccessFormCreate from './modalBodies/SuccessFormCreate';
import SuccessFormRestore from './modalBodies/SuccessFormRestore';
import SuccessFormUpdate from './modalBodies/SuccessFormUpdate';
import Warning from './modalBodies/Warning';

import './style.scss';

type OwnProps = {
    data: {
        title: string;
        bodyType:
            | 'SuccessFormCreate'
            | 'SuccessFormUpdate'
            | 'SuccessFormRestore'
            | 'ArchiveForm'
            | 'RestoreForm'
            | 'ClearAllFieldsForm'
            | 'ResetFieldsForm'
            | 'CancelForm'
            | 'ErrorForm'
            | 'Success'
            | 'Warning';
        toProfileLabel: string;
        entityName: string;
        leftButtonTitle?: string;
        rightButtonTitle?: string;
        entity?: string;
        isShowEntityViewButton?: boolean;
        message?: string;
        isAccountRestored?: boolean;
        errorMessage?: string;
        customEntityName?: string;
        buttonType?: 'warning' | 'danger' | 'success';
    };
    handlers: {
        leftButtonHandler();
        rightButtonHandler();
    };
    onClose();
};

const InformationModal: React.FC<OwnProps> = (props) => {
    const {
        data,
        data: {
            bodyType,
            buttonType,
            title,
            leftButtonTitle,
            rightButtonTitle,
            entityName,
            entity,
            isShowEntityViewButton = true,
            toProfileLabel,
            customEntityName,
            message,
            errorMessage,
            isAccountRestored,
        },
        handlers,
        onClose,
    } = props;

    const leftButtonHandler = () => {
        if (handlers.leftButtonHandler) {
            handlers.leftButtonHandler();
        }
        onClose();
    };

    const rightButtonHandler = () => {
        if (handlers.rightButtonHandler) {
            handlers.rightButtonHandler();
        }
        onClose();
    };

    const getModalBodyOptions = (): {
        modalBody: JSX.Element | string;
        isSuccessfullyCreatedModal: boolean;
        isSuccessfullyUpdatedModal: boolean;
        rightButtonTitleText: string;
    } => {
        const rightButtonTitleText = rightButtonTitle || 'Ok';
        const isSuccessfullyCreatedModal = false;
        const isSuccessfullyUpdatedModal = false;

        if (bodyType === 'SuccessFormCreate') {
            return {
                modalBody: (
                    <SuccessFormCreate
                        toProfileLabel={toProfileLabel}
                        entityName={entityName}
                        customEntityName={customEntityName}
                    />
                ),
                rightButtonTitleText: rightButtonTitle || 'View List',
                isSuccessfullyCreatedModal: true,
                isSuccessfullyUpdatedModal,
            };
        }

        if (bodyType === 'SuccessFormUpdate') {
            return {
                modalBody: <SuccessFormUpdate entityName={entityName} toProfileLabel={toProfileLabel} />,
                rightButtonTitleText: 'View List',
                isSuccessfullyUpdatedModal: true,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'SuccessFormRestore') {
            return {
                modalBody: (
                    <SuccessFormRestore
                        entityName={entityName}
                        toProfileLabel={toProfileLabel}
                        isAccountRestored={isAccountRestored}
                    />
                ),
                rightButtonTitleText: 'View List',
                isSuccessfullyUpdatedModal: true,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'ArchiveForm') {
            return {
                modalBody: <ArchiveForm toProfileLabel={toProfileLabel} entityName={entityName} />,
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'RestoreForm') {
            return {
                modalBody: (
                    <RestoreForm
                        entityName={entityName}
                        customEntityName={customEntityName}
                        toProfileLabel={toProfileLabel}
                    />
                ),
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'ClearAllFieldsForm') {
            return {
                modalBody: <ClearAllFieldsForm />,
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'ResetFieldsForm') {
            return {
                modalBody: <ResetFieldsForm />,
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'CancelForm') {
            return {
                modalBody: <CancelForm />,
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'ErrorForm') {
            return {
                modalBody: <ErrorForm data={data} message={errorMessage} />,
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'Success') {
            return {
                modalBody: <Success message={message} />,
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        if (bodyType === 'Warning') {
            return {
                modalBody: <Warning message={errorMessage} />,
                rightButtonTitleText,
                isSuccessfullyUpdatedModal,
                isSuccessfullyCreatedModal,
            };
        }

        return {
            modalBody: 'modal body content',
            rightButtonTitleText,
            isSuccessfullyUpdatedModal,
            isSuccessfullyCreatedModal,
        };
    };

    const {
        modalBody,
        isSuccessfullyUpdatedModal,
        isSuccessfullyCreatedModal,
        rightButtonTitleText,
    } = getModalBodyOptions();

    const linkProps = {
        [entityName]: entity,
        type: 'button',
        action: 'view',
        content: `View Profile`,
    };
    const ButtonEntityView = LinksMap[entityName];
    const isSuccessCreateOrUpdate = isSuccessfullyCreatedModal || isSuccessfullyUpdatedModal;
    const modalWithoutFooter = isEmpty(buttonType);
    const isWarningButtonType = buttonType === 'warning';
    const isDangerButtonType = buttonType === 'danger';
    const isSuccessfulButtonType = buttonType !== 'warning' && buttonType !== 'danger';
    const isShowEntityView = isShowEntityViewButton && isSuccessCreateOrUpdate && Boolean(entity);

    return (
        <Modal show={true} onHide={onClose} className="info-modal" backdrop="static">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{modalBody}</Modal.Body>

            {modalWithoutFooter ? null : (
                <Modal.Footer>
                    <div>{leftButtonTitle ? <Button onClick={leftButtonHandler}>{leftButtonTitle}</Button> : null}</div>

                    <div className="d-flex">
                        {isDangerButtonType ? (
                            <Button
                                onClick={rightButtonHandler}
                                className="mr10"
                                colorTheme="red"
                                buttonSize="small"
                                defaultFocus={true}
                            >
                                {rightButtonTitleText}
                            </Button>
                        ) : null}
                        {isWarningButtonType ? (
                            <Button
                                onClick={rightButtonHandler}
                                className="mr10"
                                colorTheme="yellow"
                                buttonSize="small"
                            >
                                {rightButtonTitleText}
                            </Button>
                        ) : null}
                        {isSuccessfulButtonType ? (
                            <Button
                                onClick={rightButtonHandler}
                                className="mr10"
                                colorTheme={isSuccessCreateOrUpdate ? 'grey' : 'green'}
                                buttonSize="small"
                            >
                                {rightButtonTitleText}
                            </Button>
                        ) : null}

                        {isShowEntityView ? (
                            <ButtonEntityView {...linkProps} onClick={onClose} colorTheme="green" />
                        ) : null}
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default InformationModal;
