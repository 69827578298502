import * as appActions from 'store/actions';

import Truck from 'core/entities/Truck/types';
import * as requests from 'core/gateways/TrucksNearbyApiGateway/requests';

import * as types from 'pages/TrucksNearby/redux/actionTypes/workWithCompanies';
import {transformDataToRequestBody} from 'pages/TrucksNearby/redux/mappers/workWithCompanies';
import * as selectors from 'pages/TrucksNearby/redux/selectors/workWithCompanies';

const getTruckCompanies = (params: {truckID: Truck['id']}) => async (dispatch, getState) => {
    const {truckID} = params;

    const state = getState();

    const requestPayload = transformDataToRequestBody({state});

    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.getTruckCompaniesRequest({truckID, requestPayload});

        return {items: data || []};
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const initWorkWithCompanies = (params: {truck: Truck}) => async (dispatch) => {
    const {truck} = params;

    const searchResult = await dispatch(getTruckCompanies({truckID: truck.id}));

    dispatch({type: types.WORK_WITH_COMPANIES_INITIALIZED, payload: {truck, companies: searchResult?.items}});
};

export const getCompaniesByOrderBy = () => async (dispatch, getState) => {
    const state = getState();

    const truck = selectors.getTruck(state);
    const companies = selectors.getTruckCompanies(state);

    if (!truck || !companies) {
        return null;
    }

    const searchResult = await dispatch(getTruckCompanies({truckID: truck!.id}));

    if (!searchResult) {
        return null;
    }

    dispatch({type: types.WORK_WITH_COMPANIES_ORDER_BY_RECEIVED, payload: {companies: searchResult?.items}});
};

export const clearWorkWithCompaniesState = () => (dispatch) => {
    dispatch({type: types.WORK_WITH_COMPANIES_STATE_CLEARED});
};
