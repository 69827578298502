import React from 'react';
import {Field} from 'redux-form';

import {searchOwnersByTitle} from 'core/gateways/OwnerApiGateway/requests';

import SingleAsyncSelect from '../SingleAsyncSelect';

import {transformOwnersToSelectOptions, transformOwnersWithPhoneLabelToSelectOptions} from './utils';

const MIN_ITEMS_TO_HAS_MORE = 8;

type OwnProps = {
    labelWithPhoneNumber?: boolean;
    withoutOwnerDriver?: boolean;
    closeMenuOnSelect?: boolean;
    isFetchOnMount?: boolean;
    errorPlacement?: string;
    valueAsObject?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    label?: string;
    name: string;

    onSelect?: (option) => void;
};

const SingleOwnersAsyncSelect: React.FC<OwnProps> = (props) => {
    const {
        placeholder = 'choose owner',
        labelWithPhoneNumber = false,
        closeMenuOnSelect = true,
        isFetchOnMount = false,
        valueAsObject = false,
        isClearable = false,
        isDisabled = false,
        withoutOwnerDriver,
        errorPlacement,
        onSelect,
        label,
        name,
    } = props;

    const loadOptions = async (searchValue, loadedOptions, {page}) => {
        try {
            const {data} = await searchOwnersByTitle({
                is_owner: withoutOwnerDriver,
                title: searchValue,
                'per-page': 50,
                page,
            });

            let ownersOptions;

            if (labelWithPhoneNumber) {
                ownersOptions = transformOwnersWithPhoneLabelToSelectOptions(data?.items, valueAsObject);
            } else {
                ownersOptions = transformOwnersToSelectOptions(data?.items, valueAsObject, withoutOwnerDriver);
            }

            return {
                options: ownersOptions,
                hasMore: ownersOptions.length > MIN_ITEMS_TO_HAS_MORE,
                additional: {page: page + 1},
            };
        } catch (error) {
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    return (
        <>
            {label ? <label className="form-label">{label}</label> : null}

            <Field
                closeMenuOnSelect={closeMenuOnSelect}
                isFetchOnMount={isFetchOnMount}
                errorPlacement={errorPlacement}
                component={SingleAsyncSelect}
                loadOptions={loadOptions}
                isClearable={isClearable}
                placeholder={placeholder}
                isDisabled={isDisabled}
                onSelect={onSelect}
                name={name}
            />
        </>
    );
};

export default SingleOwnersAsyncSelect;
