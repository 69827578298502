import {useSelector} from 'react-redux';

import * as selectors from 'pages/Trucks/redux/selectors/selectCompanies';

type UseSelectCompaniesModalState = {
    isInitialCompaniesLoaded: ReturnType<typeof selectors.getIsInitialCompaniesLoaded>;
    initialCompanies: ReturnType<typeof selectors.getInitialCompanies>;
    allAllianceCount: ReturnType<typeof selectors.getAllAllianceCount>;
    companies: ReturnType<typeof selectors.getCompanies>;
};

const useSelectCompaniesModalState = (): UseSelectCompaniesModalState => ({
    isInitialCompaniesLoaded: useSelector(selectors.getIsInitialCompaniesLoaded),
    initialCompanies: useSelector(selectors.getInitialCompanies),
    allAllianceCount: useSelector(selectors.getAllAllianceCount),
    companies: useSelector(selectors.getCompanies),
});

export default useSelectCompaniesModalState;
